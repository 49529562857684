import { useAppDispatch } from "../../hooks/useAppDispatch";
import { setSidePanelOpen } from "../../slices/pagesSlice";

export const ToggleTriangle = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="toggle-triangle" onClick={() => dispatch(setSidePanelOpen(true))}>
      <div className="triangle closed" />
    </div>
  );
};
