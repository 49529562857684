/**
 * Configuration setup
 */

// eslint-disable-next-line @typescript-eslint/no-require-imports
var constants = typeof globalConstants == "undefined" ? require("./index") : globalConstants;
constants.INSTANCE_ID = getInstanceID();

// var configModule = angular.module("configuration", []);
// for (var name in constants) {
//   configModule.constant(name, constants[name]);
// }

/**
 * Generates a random number between 0 and 1 (not including 1). It uses a cryptographically secure library when possible (window.crypto or window.mscrypto for some ie versions), and falls back to Math.random
 *
 * @returns {number}
 */
function random() {
  try {
    if (window && (window.crypto || window.msCrypto)) {
      return (window.crypto || window.msCrypto).getRandomValues(new Uint32Array(1))[0] / 0x100000000; // 0x100000000 = max possible value for Uint32 + 1
    }
  } catch {
    // continue on, using basic math library. Be very conservative in how the warning is displayed to avoid another error
    if (window && window.console)
      window.console.warn(
        "Failed to use cryptography library while generating a random number. Falling back to Math.random"
      );
  }

  // Fall back to using the traditional (not cryptographically secure, but more universal) Math.random library
  return Math.random();
}

/**
 * Generates a random whole number between a start number and an end number (inclusive).
 * It uses a cryptographically secure library when possible (window.crypto or window.mscrypto for some ie versions), and
 * falls back to Math.random
 * @param min
 * @param max
 * @returns {int}
 */
function randomBetween(min, max) {
  return Math.floor(random() * Math.abs(max + 1 - min)) + min;
}

/**
 * Get a random string uniquely identifying the current instance of the app
 * @return {string}
 */
function getInstanceID() {
  var id_length = 15; // The length of the random string to generate, in characters
  var id = "";

  while (id.length < id_length) {
    // Valid characters are 0-9, and a-z (lowercase). You can get this number by converting a random number between 0 to 36 to base 36.
    id += randomBetween(0, 35).toString(36);
  }

  return id;
}

// export default configModule.name;
