import { useState } from "react";
import { Modal } from "./Modal";

declare global {
  interface Window {
    SHORT_TITLE: string;
  }
}

export const NewVersionAvailableModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);

  return (
    <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
      <div className="modal-header">
        <h3>A new version of {window.SHORT_TITLE} is available.</h3>
      </div>
      <div className="modal-body">
        <p>Please save your work, then refresh the page to update to the new version.</p>
      </div>
      <div className="modal-footer">
        <button className="btn plain" onClick={() => setIsModalOpen(false)}>
          Not now
        </button>
        <button className="btn" onClick={() => window.location.reload()}>
          Refresh
        </button>
      </div>
    </Modal>
  );
};
