/* eslint-disable @typescript-eslint/no-require-imports */
import { datadogRum } from "@datadog/browser-rum";

import { DOMAIN } from "../configuration";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const applicationId = require("../../src/configuration/index").DATADOG_APP_ID;

export const bootstrap = () => {
  if (!applicationId) return;
  datadogRum.init({
    applicationId,
    clientToken: "pub21ec35b21120b7c03499924823dafb35",
    site: "datadoghq.com",
    service: DOMAIN,
    env: process.env.ENV_NAME,
    // eslint-disable-next-line @typescript-eslint/no-require-imports
    version: window.epiVersion.tagDescription,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogRum.startSessionReplayRecording();
};
