import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../Modal";

type Callback = (r: string | Promise<string>) => void;

export interface ConfirmationModalHandle {
  open: (message: string, proceed?: string, cancel?: string) => Promise<string>;
}

export const ConfirmationModal = forwardRef<ConfirmationModalHandle>(function ConfirmationModal(props, ref) {
  const [text, setText] = useState({ message: "", proceed: "Continue", cancel: "Cancel" });
  const [isOpen, setIsOpen] = useState(false);
  const resolver = useRef<Callback>();
  const rejecter = useRef<Callback>();

  function handleProceed() {
    if (resolver.current) {
      resolver.current("done!");
    }
    setIsOpen(false);
  }

  function handleCancel() {
    if (rejecter.current) {
      rejecter.current("not done");
    }
    setIsOpen(false);
  }

  // Open the modal and return a promise which will be resolved or reject based on which button the
  // user clicks.
  async function open(message: string, proceed?: string, cancel?: string): Promise<string> {
    setIsOpen(true);
    proceed = proceed || text.proceed;
    cancel = cancel || text.cancel;
    setText({ message, proceed, cancel });

    return new Promise<string>((resolve, reject) => {
      resolver.current = resolve;
      rejecter.current = reject;
    });
  }

  // Provide an imperative API so that actions can be chained onto the user's decision.
  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Modal isOpen={isOpen} onClose={handleCancel} className="truterritory-save-permalink-modal">
        <div className="modal-body">
          <h4>Warning</h4>
          <p>{text.message}</p>
          <div className="controls">
            <button className="btn" onClick={handleProceed}>
              {text.proceed}
            </button>
            <button className="btn plain" onClick={handleCancel}>
              {text.cancel}
            </button>
          </div>
          <div className="modal-footer"></div>
        </div>
      </Modal>
    </>
  );
});
