import moment from "moment";
import { store } from "../store";

/**
 * @name DateRange
 * @memberOf common
 * @ngdoc service
 * @description Service to interact with date ranges
 */

const first12 = moment().subtract(12, "months");
const last12 = moment().subtract(1, "months");
const last12Text = `(${first12.format("MMM YY")} - ${last12.format("MMM YY")})`;

const ranges = [
  { value: "all", name: "All" },
  { value: "today", name: "Today" },
  { value: "2", name: "Last 2 Days" },
  { value: "14", name: "Last 14 Days" },
  { value: "30", name: "Last 30 Days" },
];

const complexRanges = [
  { value: "mtd", name: "This Month" },
  { value: "last-month", name: "Last Month" },
  { value: "last-quarter", name: "Last Quarter" },
  { value: "ytd", name: "This Year" },
  { value: "last-year", name: `Last Year (${new Date().getFullYear() - 1})` },
  { value: "12-months", name: `Last 12 Months ${last12Text}` },
];

export const getRanges = (complex) => {
  let resultRanges = [...ranges];
  if (complex) resultRanges = resultRanges.concat([...complexRanges]).concat(_getFiscalYear());

  resultRanges.push({ value: "custom", name: "Custom" });

  return resultRanges;
};

const _getFiscalYear = () => {
  const { entityID, entityList } = store.getState().session ?? {};
  const entity = (entityList ?? []).find((e) => e.ID === entityID);
  if (entity && entity.fyMonth && entity.fyDay) return [{ value: "fytd", name: "Fiscal Year To Date" }];
  return [];
};

// const _getEntity = () => {
//   return _.find(Session.getAll().entityList, { ID: parseInt($state.params.entityID) });
// };

// export const getRange = (preset) => {
//   if (typeof preset === "undefined") return;

//   const range = { range: preset };

//   switch (preset) {
//     case "all":
//     case "today":
//       range.minDate = new Date();
//       range.maxDate = new Date();
//       return range;

//     case "mtd":
//       range.minDate = new Date();
//       range.minDate.setDate(1);
//       range.maxDate = new Date();
//       break;

//     case "last-month":
//       range.minDate = new Date();
//       range.minDate.setMonth(range.minDate.getMonth() - 1);
//       range.minDate.setDate(1);
//       range.maxDate = new Date();
//       range.maxDate.setDate(0);
//       break;

//     case "last-quarter":
//       range.minDate = new Date();
//       range.minDate = new Date(range.minDate.getFullYear(), range.minDate.getMonth() - (range.minDate.getMonth() % 3));
//       range.minDate.setHours(0, 0, 0, 0);
//       range.minDate = moment(range.minDate).subtract(1, "quarters").toDate();
//       range.maxDate = moment(range.minDate).add(1, "quarters").subtract(1, "days").toDate();
//       range.maxDate.setHours(23, 59, 59, 999);
//       break;

//     case "ytd":
//       range.minDate = new Date();
//       range.minDate.setDate(1);
//       range.minDate.setMonth(0);
//       range.minDate.setHours(0, 0, 0, 0);
//       range.maxDate = new Date();
//       break;

//     case "last-year":
//       const lastYear = new Date().getFullYear() - 1;
//       range.minDate = new Date(lastYear, 0, 1);
//       range.maxDate = new Date(lastYear, 12, 0);
//       break;

//     case "12-months":
//       range.minDate = new Date();
//       range.minDate.setMonth(range.minDate.getMonth() - 12);
//       range.minDate.setDate(1);
//       range.maxDate = new Date();
//       range.maxDate.setDate(0);
//       break;

//     case "fytd":
//       const entity = _getEntity();
//       range.minDate = moment({ month: entity.fyMonth - 1, day: entity.fyDay }).toDate();
//       range.maxDate = new Date();
//       break;

//     case "custom":
//       range.minDate = preset.minDate;
//       range.maxDate = preset.maxDate;
//       break;

//     default:
//       range.minDate = new Date();
//       range.minDate.setDate(range.minDate.getDate() - preset);
//       range.maxDate = new Date();
//   }

//   return range;
// };
