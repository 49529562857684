import { useEffect } from "react";
import { store } from "../../store";
import { updateGlobalState } from "../../slices/appSlice";

declare global {
  interface Window {
    CURRENT_HASH: string;
  }
}

export const VersionPoller = () => {
  const pollingInterval = 60 * 60 * 1000; // 1 hour

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const version = await fetchVersion();
      if (version !== window.CURRENT_HASH) {
        clearInterval(intervalId);
        store.dispatch(updateGlobalState({ error: { code: 400, exception: "newVersionAvailable" } }));
      }
    }, pollingInterval);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

async function fetchVersion(): Promise<string | null> {
  const resp = await fetch("/version.json?" + new Date().getTime());
  const body = await resp.json();

  if (resp.status != 200) {
    console.error("Failed loading UI version.");
  }

  return body?.version || null;
}
