import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const HashRedirect = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash) {
      const url = window.location.hash.replace("#", "").replace("//", "/");
      navigate(url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};
