import { Box, Fade } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Point } from "geojson";
import { Field, Formik } from "formik";
import { Circle } from "../../pages/TruTerritory/MapBackendActions";
import { createPortal } from "react-dom";
import * as turf from "@turf/turf";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { hideShapeOverlay, showShapeOverlay } from "../../slices/pagesSlice";

interface IProps {
  point: Point;
  position: { clientX: number; clientY: number; mapX: number; mapY: number };
  onSubmit: (input: Circle) => void;
  onChange?: (input: Circle) => void;
  onCancel?: () => void;
}

export const RadiusInput: React.FC<JSX.IntrinsicAttributes & IProps> = ({
  point,
  position,
  onSubmit,
  onChange,
  onCancel,
}: IProps) => {
  const dispatch = useAppDispatch();
  const boxRef = useRef<HTMLElement>(null);
  const prevPos = useRef({ ...position, mapX: position.mapX + 25, mapY: position.mapY + 25 });
  const container = useRef(document.getElementsByClassName("leaflet-overlay-pane").item(0));

  const [isOpen, setIsOpen] = useState(true);
  const [radius, setRadius] = useState(2.5);
  const [isDragging, setIsDragging] = useState(false);

  // Ensure that the popup closes if the user right-clicks somewhere else on the map.
  useEffect(() => {
    document.getElementById("map")?.addEventListener("contextmenu", close);

    return () => {
      document.getElementById("map")?.removeEventListener("contextmenu", close);
    };
  }, []);

  // Register listening when dragging the popup.
  useEffect(() => {
    if (!isDragging) {
      return;
    }

    document.addEventListener("mouseup", stopDrag);
    document.addEventListener("mousemove", drag);

    return () => {
      document.removeEventListener("mouseup", stopDrag);
      document.removeEventListener("mousemove", drag);
    };
  }, [isDragging]);

  // Draw the circle when the radius changes.
  useEffect(() => {
    if (radius > 0) {
      showCircle(radius);
    }
  }, [radius]);

  if (!container.current) return <></>;

  /**
   * Start the dragging on mouse down, while preventing the event from dragging the underlying map.
   */
  function startDrag(e: React.MouseEvent<HTMLElement>) {
    e.stopPropagation();
    // L.DomEvent.stopPropagation(e.);
    setIsDragging(true);
    prevPos.current = { ...prevPos.current, clientX: e.clientX, clientY: e.clientY };
  }

  /**
   * End the dragging on mouse up.
   */
  function stopDrag() {
    setIsDragging(false);
  }

  /**
   * Handle mouse move while dragging.
   */
  function drag(e: MouseEvent) {
    if (!boxRef.current) {
      return;
    }

    prevPos.current = {
      mapX: prevPos.current.mapX + (e.clientX - prevPos.current.clientX),
      mapY: prevPos.current.mapY + (e.clientY - prevPos.current.clientY),
      clientX: e.clientX,
      clientY: e.clientY,
    };
    boxRef.current.style.transform = `translate3d(${prevPos.current.mapX}px, ${prevPos.current.mapY}px, 0px)`;
  }

  /**
   * Make a Circle object.
   */
  function makeCircle(radius: number): Circle {
    return { x: point.coordinates[0], y: point.coordinates[1], radius, srid: 4326 };
  }

  /**
   * Draw a GeoJSON circle from the chosen radius.
   */
  function showCircle(radius: number) {
    if (radius <= 0) {
      return;
    }

    const shape = turf.circle(point, radius, { units: "miles", steps: 100 });
    dispatch(showShapeOverlay(shape));
  }

  /**
   * Close the popup.
   */
  function close() {
    setIsOpen(false);
    dispatch(hideShapeOverlay());
    if (onCancel) {
      onCancel();
    }
  }

  return (
    <>
      {createPortal(
        <Fade in={isOpen} timeout={250}>
          <Box
            className="truterritory-report-radius-input"
            ref={boxRef}
            sx={{
              p: 1,
              paddingBottom: 2,
              width: 300,
              bgcolor: "background.paper",
              boxShadow: 4,
              borderRadius: "3px",
              transform: `translate3d(${prevPos.current.mapX}px, ${prevPos.current.mapY}px, 0px)`,
              position: "absolute",
              zIndex: 300,
              cursor: "auto",
            }}
            // Prevent zooming in on double click.
            onDoubleClick={(e: React.MouseEvent<HTMLElement>) => e.stopPropagation()}
          >
            <div
              onMouseDown={startDrag}
              style={{
                position: "absolute",
                right: "0",
                top: "0",
                backgroundColor: "#dee",
                padding: "5px",
                cursor: "grab",
                userSelect: "none",
              }}
            >
              Drag
            </div>
            <Formik
              initialValues={{ radius }}
              onSubmit={(values) => {
                setIsOpen(false);
                dispatch(hideShapeOverlay());
                onSubmit(makeCircle(values.radius));
              }}
            >
              {({ handleSubmit, handleChange, setFieldError, errors }) => (
                <form className="form-horizontal" onSubmit={handleSubmit}>
                  <p>Radius</p>
                  <div className="col-md-12">
                    <Field
                      type="number"
                      name="radius"
                      step="0.5"
                      style={{ width: "35%", marginRight: "0.5em" }}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const val = parseFloat(e.currentTarget.value);
                        const min = 0;
                        const max = 250;
                        if (val <= min) {
                          setFieldError("radius", `Radius must be greater than ${min}.`);
                          return;
                        }

                        if (val > max) {
                          setFieldError("radius", `Radius must not be greater than ${max}.`);
                          return;
                        }

                        handleChange(e);
                        setRadius(parseFloat(e.currentTarget.value));

                        if (onChange) {
                          onChange(makeCircle(parseFloat(e.currentTarget.value)));
                        }
                      }}
                    />
                    miles
                    <p className="text-danger">{errors.radius}</p>
                  </div>

                  <div className="controls col-md-12">
                    <button className="btn light" type="submit">
                      Download Report
                    </button>
                    <button className="btn plain" type="button" onClick={close}>
                      Cancel
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </Box>
        </Fade>,
        container.current
      )}
      ;
    </>
  );
};
