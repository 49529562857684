import { useMemo } from "react";

import { useAppSelector } from "../hooks/useAppSelector";
import { Link } from "react-router-dom";
import { useMenu } from "../hooks/useMenu";

export const Menu = () => {
  const { entityID, activePage } = useAppSelector((state) => state.app);

  const { enabled } = useMenu();
  const computedMenu = useMemo(() => enabled.filter((i) => i.visible !== false), [enabled]);

  if (computedMenu && computedMenu?.length <= 1) return <></>; // if we only have 1 menu item, we don't need a menu

  return (
    <nav id="side-nav-left" className="col-xs-2">
      <ul className="nav nav-pills nav-stacked nav-collapse">
        {computedMenu?.map((item: { id: string }, index: number) => (
          <li
            className={[item.id, item.id === activePage ? "active" : "", entityID ? "" : "disabled"].join(" ")}
            key={index}
          >
            <Link to={`/${item.id}/${entityID}`} onClick={(e) => !entityID && e.preventDefault()} />
          </li>
        ))}
      </ul>
    </nav>
  );
};
