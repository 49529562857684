/* eslint-disable react/prop-types */
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../components/Modal";

type Callback = (r: Promise<string>) => void;

export interface IOpenPasswordModalProps {
  first: boolean;
}
export interface OpenPasswordModalHandler {
  open: (props: IOpenPasswordModalProps) => Promise<string>;
}

export const OpenPasswordModal = forwardRef<OpenPasswordModalHandler>(function OpenPasswordModal(_props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<IOpenPasswordModalProps>();
  const [password, setPassword] = useState("");

  const resolver = useRef<Callback>();
  const rejecter = useRef<Callback>();

  function handleProceed(e?: React.FormEvent) {
    e?.preventDefault();
    if (resolver.current) {
      resolver.current(Promise.resolve(password));
    }
    setIsOpen(false);
    setProps(undefined);
  }

  function handleCancel() {
    if (rejecter.current) {
      rejecter.current(Promise.reject("User cancelled"));
    }
    setIsOpen(false);
    setProps(undefined);
  }

  async function open(props: IOpenPasswordModalProps): Promise<string> {
    setIsOpen(true);
    setProps(props);
    return new Promise<string>((resolve, reject) => {
      resolver.current = resolve;
      rejecter.current = reject;
    });
  }

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClose={handleCancel} skipExitAnimation>
      <div className="modal-body">
        <form onSubmit={handleProceed}>
          <p>
            {props?.first
              ? "A password is required to view this map."
              : "The password you entered is incorrect. Please try again."}
          </p>
          <input type="password" value={password} onChange={({ target }) => setPassword(target.value)} autoFocus />
          <button className="btn light-blue" onClick={handleProceed} disabled={!password}>
            Continue
          </button>
        </form>
      </div>
    </Modal>
  );
});
