import { isRouteErrorResponse, useRouteError } from "react-router-dom";

export const NotFound = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <div id="content">
          <h1>404 Not Found</h1>
          <p>I&apos;m sorry, the page you requested could not be found</p>
        </div>
      );
    } else {
      return (
        <div id="content">
          <h1>Oops!</h1>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
          {error.data?.message && <p>{error.data.message}</p>}
        </div>
      );
    }
  }

  return <div id="content">An error occurred: {JSON.stringify(error)}</div>;
};
