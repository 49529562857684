import React, { ChangeEventHandler } from "react";
import { Row } from "./layout/Row";

interface IFormControl {
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type?: React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>["type"];
  label?: string;
  placeholder?: string;
  value?: string;
  name?: string;
  error?: string | boolean | string[];
  labelSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  inputSize?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  multiline?: boolean;
  rows?: number;
  cols?: number;
}

export const TextField: React.FC<IFormControl> = ({
  label,
  type = "text",
  placeholder,
  value,
  name,
  error,
  onChange,
  onBlur,
  labelSize = 4,
  inputSize = 8,
  multiline = false,
  rows,
  cols,
}: IFormControl) => {
  return (
    <Row>
      {label && <label className={"col-md-" + labelSize}>{label}</label>}
      {multiline ? (
        <textarea
          className={`col-md-${inputSize} ${error ? "invalid" : ""}`}
          value={value || ""}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          rows={rows}
          cols={cols}
        />
      ) : (
        <input
          className={`col-md-${inputSize} ${error ? "invalid" : ""}`}
          type={type}
          value={value || ""}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          style={{ margin: 0 }}
        />
      )}
      {typeof error !== "boolean" && <div className="col-md-12 text-danger">{error}</div>}
    </Row>
  );
};
