/**
 * Service to take over the console logging and keep a running history
 */

import { DEV } from "../configuration";

class Logger {
  DEBUG_LEVEL: string;
  LOG_LEVEL: string;
  WARN_LEVEL: string;
  ERROR_LEVEL: string;
  _history: { [key: string]: unknown[] };

  constructor() {
    this.DEBUG_LEVEL = "debug";
    this.LOG_LEVEL = "log";
    this.WARN_LEVEL = "warn";
    this.ERROR_LEVEL = "error";

    this._history = {};
    this._history[this.DEBUG_LEVEL] = [];
    this._history[this.LOG_LEVEL] = [];
    this._history[this.WARN_LEVEL] = [];
    this._history[this.ERROR_LEVEL] = [];
  }

  start() {
    window.onerror = (errorMsg, file, lineNumber) => {
      this.logError(this.ERROR_LEVEL, errorMsg, lineNumber, file);
    };

    if (!window.console) window.console = {} as Console;

    const replaceMethod = (method: keyof Console) => {
      const original = window.console[method];

      const replacement = (...args: unknown[]) => {
        this.logError(method, args);

        if (original.apply) {
          if (DEV) (original as (...args: unknown[]) => void).apply(console, args);
        } else {
          const message = Array.prototype.slice.apply(args).join(" ");
          if (DEV) (original as (...args: unknown[]) => void)(message);
        }
      };

      return replacement;
    };

    const methods = ["debug", "log", "warn", "error"] as (keyof Console)[];

    if (DEV) return;

    for (let i = 0; i < methods.length; i++) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.console[methods[i]] = replaceMethod(methods[i]) as any;
    }
  }

  logError(errorLevel: string, message: unknown, lineNumber?: number, file?: string) {
    const date = new Date();

    const error = {
      message: message,
      lineNumber: lineNumber,
      file: file,
      url: window.location,
      state: window.location.pathname,
      timestamp: date,
    };
    this._history[errorLevel].push(error);
  }

  getAll() {
    return this._history;
  }
}

const sharedInst = new Logger();
sharedInst.start();

export default sharedInst;
