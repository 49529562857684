import { useFormik } from "formik";
import { useEffect, useState } from "react";

/**
 * This hook is used to dynamically change the validation behavior of Formik.
 *  It will validate the form on change and blur only if the form has been submitted at least once.
 */
export const useDynamicFormik: typeof useFormik = (params) => {
  const [submitted, setSubmitted] = useState(false);
  const formik = useFormik({
    ...params,
    validateOnChange: params.validateOnChange || submitted,
    validateOnBlur: params.validateOnBlur || submitted,
  });
  useEffect(() => {
    if (formik.submitCount > 0) {
      setSubmitted(true);
    }
  }, [formik.submitCount]);
  return formik;
};
