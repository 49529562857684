import { useEffect, useState } from "react";
import { store } from "../../store";
import { setError, setSession } from "../../slices/appSlice";
import { ISession } from "../../types/ISession";
import { API_ROOT } from "../../configuration";
import { IApiError } from "../../types/IApiResponse";
import { POLL_TIME } from "../../constants";
import { useAppSelector } from "../../hooks/useAppSelector";

/**
 * Long-poll the API for up-to-date session data.
 **/
export const SessionPoller = () => {
  const { session } = useAppSelector((state) => state.app);
  const [controller, setController] = useState<AbortController>();

  // Start polling on component mount, and abort polling on unmount.
  useEffect(() => {
    if (!controller) {
      const controller = new AbortController();
      setController(controller);
      const signal = controller.signal;
      poll(signal);
    }

    return () => {
      controller?.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  // Iteratively poll until the signal is aborted.
  const poll = async (signal: AbortSignal) => {
    while (!signal.aborted) {
      try {
        store.dispatch(setSession(await getSession(signal, POLL_TIME, store.getState().app.session?.hash)));
      } catch (e) {
        if (e instanceof LoginRequiredError && e.apiError) {
          store.dispatch(setError(e.apiError));
          setController(undefined);
          return; // Stop polling on login required.
        } else if (!signal.aborted) {
          console.error("Error in polling:", e);
        }
      }
    }
  };

  return <></>;
};

/**
 * Make a cancelable API call for session data.
 */
async function getSession(signal: AbortSignal, poll?: number, hash?: string): Promise<ISession> {
  const params = new URLSearchParams();
  if (poll) params.append("poll", poll.toString());
  if (hash) params.append("hash", hash);

  const resp = await fetch(`${API_ROOT}/session?${params.toString()}`, {
    signal,
    credentials: "include",
    headers: { Accept: "application/json" },
  });

  const body = await resp.json();

  if (resp.status == 401 || resp.status == 403) {
    throw new LoginRequiredError("Failed loading session: " + body?.status?.message, body?.status);
  }

  if (resp.status != 200) {
    throw new Error("Failed loading session: " + body?.status?.message);
  }

  return body?.data;
}

class LoginRequiredError extends Error {
  constructor(message: string, apiError?: IApiError) {
    super(message);
    this.apiError = apiError;
  }

  apiError?: IApiError;
}
