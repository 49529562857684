/**
 * A Leaflet layer for text layers
 */
export const LabelLayer = L.Layer.extend({
  options: {
    class: "map-feature-label",
    text: "",
    fontSize: 26,
    enableEditing: true,
  },
  _visible: false,
  _parent: null,
  _parentBounds: null,
  _latlng: null,
  _editing: false,
  _overBtn: false,

  // initialize a new label
  initialize: function (layer, options) {
    L.setOptions(this, options);
    this._parent = layer;
    this._parentBounds = layer.getBounds();
    try {
      this._latlng = this._getCentroid(layer.feature);
    } catch (e) {
      console.error(e);
    }
  },

  onAdd: function (map) {
    if (!this._latlng) return;

    this._map = map;

    // add a moveend event listener for updating layer's position
    map.on("moveend", this._adjustToViewport, this);

    this._el = L.DomUtil.create("div", this.options.class + " leaflet-zoom-hide");
    $(this._el).append(this._getLabel());

    if (this.options.enableEditing) {
      let editBtn = this._getEditButton();
      $(this._el).append(editBtn);
    }

    this._adjustToViewport();
  },

  onRemove: function (map) {
    if (!this._latlng) return;

    // remove layer's DOM elements and listeners
    if (this._el && this._visible) map.getPanes().overlayPane.removeChild(this._el);
    this._visible = false;
    map.off("moveend", this._adjustToViewport, this);
  },

  getLatLng: function () {
    return this._latlng;
  },

  _getLabel: function () {
    return '<span class="text">' + (this.options.text || "") + "</span>";
  },

  _getEditButton() {
    let btn = $('<span class="icon-edit"></span>');

    // Hide the button by default.
    btn.hide();

    // Show the edit button when hovering over the polygon.
    this._parent.on("mouseover", () => {
      if (this._editing) return;

      btn.show();
    });

    // Hide the edit button when mouse leaves the polygon (but put it
    // in a timeout in case the mouse goes to the edit button itself).
    this._parent.on("mouseout", () => {
      if (this._editing) return;

      this._hideIconTimeout = setTimeout(function () {
        btn.hide();
      }, 0);
    });

    // If the mouse is over the edit button, don't hide it!
    btn.mouseenter(() => {
      if (this._hideIconTimeout != null) {
        clearTimeout(this._hideIconTimeout);
        this._hideIconTimeout = null;
      }
    });

    btn.click(this._openEditor.bind(this));

    return btn;
  },

  _openEditor() {
    if (this._editing) return;

    this._editing = true;

    // Hide the label, and put this element on top of everything.
    let el = $(this._el);
    el.find(".text, .icon-edit").hide();
    el.css("z-index", 1000000);

    // Also disable map interaction while editing.
    this._map.dragging.disable();
    this._map.touchZoom.disable();
    this._map.doubleClickZoom.disable();
    this._map.scrollWheelZoom.disable();
    this._map.keyboard.disable();
    if (this._map.tap) this._map.tap.disable();

    // Create a textarea filled with the label text.
    // Add a tiny toolbar with save, cancel, and fontsize (show pre-scaled value, but set scaled; up/down buttons).
    let editor = $(`<div class="editor">
          <div class="toolbar">
            <button class="save">Save</button>
            <button class="cancel">Cancel</button>
            <select class="font-size">
              ${fontSizeOptions(this.options.fontSize)}
            </select>
          </div>
          <textarea>${this.options.text || ""}</textarea>
        </div>`);

    // On save, update label and close editor.
    editor.find(".save").click(() => {
      this.options.text = editor.find("textarea").val();
      this.options.fontSize = parseInt(editor.find("select").val());
      el.find(".text").html(this.options.text || "");
      this._closeEditor();
      this.fire("text-edit", { text: this.options.text, fontSize: this.options.fontSize });
    });
    // On close, close but don't update label.
    editor.find(".cancel").click(this._closeEditor.bind(this));

    // On font size change, update and scale font size.
    editor.find(".font-size").change((e) => {
      this._updateFontSize(e.target.value);
    });

    el.append(editor);

    function fontSizeOptions(selected) {
      nums = [8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 72, 120, 240, 500, 750, 1000];

      return _.map(nums, function (val) {
        return `<option value="${val}" ${val == selected ? 'selected="selected"' : ""}">${val}</option>`;
      });
    }
  },

  _closeEditor() {
    this._editing = false;

    // Show the label, and put this layer back into its place in the stack.
    let el = $(this._el);
    el.find(".text, .icon-edit").show();
    el.css("z-index", "");

    // Enable map interactions.
    this._map.dragging.enable();
    this._map.touchZoom.enable();
    this._map.doubleClickZoom.enable();
    this._map.scrollWheelZoom.enable();
    this._map.keyboard.enable();
    if (this._map.tap) this._map.tap.enable();

    // Remove the editor
    el.find(".editor").remove();

    this._adjustToViewport();
  },

  /**
   * Get centroid of a polygon
   * http://en.wikipedia.org/wiki/Centroid#Centroid_of_polygon
   * @param  {object} feature
   * @return {L.LatLng}
   */
  _getCentroid: function (feature) {
    var points,
      area = 0,
      lat = 0,
      lng = 0;

    // if a multipolygon, choose the biggest polygon
    if (feature.geometry.type == "MultiPolygon") {
      points = feature.geometry.coordinates[0][0];

      for (var i = 0; i < feature.geometry.coordinates.length; i++) {
        if (feature.geometry.coordinates[i][0].length > points.length) points = feature.geometry.coordinates[i][0];
      }
    }
    // If it's a point, just return it.
    else if (feature.geometry.type == "Point") return new L.LatLng(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
    else points = feature.geometry.coordinates[0];

    for (var i = 0; i < points.length - 1; i++) {
      // each point is [lng,lat]
      area += points[i][0] * points[i + 1][1] - points[i + 1][0] * points[i][1];
    }
    area = 0.5 * area;

    // get longitude (x)
    for (var i = 0; i < points.length - 1; i++) {
      lng += (points[i][0] + points[i + 1][0]) * (points[i][0] * points[i + 1][1] - points[i + 1][0] * points[i][1]);
    }
    lng = (1 / (6 * area)) * lng;

    // get latitude (y)
    for (var i = 0; i < points.length - 1; i++) {
      lat += (points[i][1] + points[i + 1][1]) * (points[i][0] * points[i + 1][1] - points[i + 1][0] * points[i][1]);
    }
    lat = (1 / (6 * area)) * lat;

    return new L.LatLng(lat, lng);
  },

  _scaleFontSize: function (fontSize, zoom, min, max) {
    let size = fontSize * Math.pow(2, zoom - 11);
    if (min && size < min) return min;
    if (max && size > max) return max;
    return size;
  },

  // Set and return scaled font size
  _updateFontSize(fontSize) {
    let scaledFontSize = this._scaleFontSize(fontSize || this.options.fontSize, this._map.getZoom());
    this._el.style.fontSize = scaledFontSize + "px";
    return scaledFontSize;
  },

  // hide label, or show label and update position
  _adjustToViewport: function () {
    let fontSize = this._updateFontSize();

    // hide label
    if (this._visible && !this._shouldBeVisible(fontSize)) {
      this._map.getPanes().overlayPane.removeChild(this._el);
      this._visible = false;
      return;
    }

    // show label
    if (!this._visible && this._shouldBeVisible(fontSize)) {
      this._map.getPanes().overlayPane.appendChild(this._el);
      this._visible = true;
    }

    // set position
    var pos = this._map.latLngToLayerPoint(this._latlng),
      size = this._getSize();
    pos.x -= size.width / 2;
    L.DomUtil.setPosition(this._el, pos);
    this._el.style.width = size.width + "px";
  },

  // Check if the parent is at all visible. If not, don't show this one.
  _shouldBeVisible(fontSize) {
    if (!this._map) return false;

    if (fontSize < 8 || fontSize > 100) return false;

    return !("_parts" in this._parent) || !!this._parent._parts.length;
  },

  _getSize: function () {
    var topLeft = this._map.latLngToLayerPoint(this._parentBounds.getNorthWest()),
      bottomRight = this._map.latLngToLayerPoint(this._parentBounds.getSouthEast());
    return {
      width: Math.abs(bottomRight.x - topLeft.x),
      height: Math.abs(topLeft.y - bottomRight.y),
    };
  },
});
