import { s3 } from "fine-uploader/lib/s3";

import { baseConfig } from "../../utils/fineUploader";
import { API_ROOT } from "../../configuration";
import { store } from "../../store";

export const ImageUploader = (editor) => {
  let wrapper = $(editor.getWrapperElement());

  ["keyup", "paste", "cut"].forEach((event) => {
    wrapper.on(event, createPreviewBoxes);
  });
  wrapper.on("click", function (event) {
    let target = $(event.target);
    if (!target.parents("fine-uploader").length && !target.hasClass("image-preview"))
      wrapper.children("fine-uploader").css("display", "none");
  });

  createUploader();

  var selectedLineNumber, uploaderElement, uploaderInstance;

  const regex = /setMarker\((.*)\)/;

  function createPreviewBoxes() {
    if (!uploaderInstance) return;

    const files = uploaderInstance.getUploads();
    editor.operation(() => {
      for (let i = 0; i < editor.lineCount(); i++) {
        const line = editor.getLineHandle(i).text;
        const start = line.indexOf("setMarker");

        editor.findMarksAt({ line: i, ch: start }).forEach((mark) => mark.clear());

        if (start < 0) continue;

        const [, fileName] = line.match(regex) ?? [];

        const previewBox = $(`<span class="image-preview box" line-number=${i}></span>`);
        previewBox.on("click", onPreviewBoxClick);

        if (fileName) {
          const file = files.find((file) => file.name === fileName && !file.parentId);

          const thumbnailUrl = file ? file.thumbnailUrl || uploaderInstance._thumbnailUrls[file.id] : null;

          if (!thumbnailUrl) previewBox.addClass("empty");
          else previewBox.removeClass("empty");

          previewBox.css("background-image", `url("${thumbnailUrl}")`);
        } else {
          previewBox.addClass("empty");
        }

        editor.setBookmark({ line: i, ch: start }, { widget: previewBox[0] });
      }
    });
  }

  function createUploader() {
    uploaderElement = $(`<div></div>`);
    uploaderElement.css("display", "none");
    uploaderElement.css("position", "absolute");
    uploaderElement.css("z-index", "100");

    const onSelect = (file) => {
      if (!uploaderInstance.getParentId(file.id)) {
        let line = editor.getLine(selectedLineNumber);
        let endCh = line.indexOf(")") == -1 ? line.length : line.indexOf(")");
        let { start, end } = {
          start: {
            line: selectedLineNumber,
            ch: line.indexOf("(") + 1,
          },
          end: {
            line: selectedLineNumber,
            ch: endCh,
          },
        };
        let replacement = `${file.name}${endCh == line.length ? ");" : ""}`;
        editor.replaceRange(replacement, start, end);
        createPreviewBoxes();
        uploaderElement.css("display", "none");
      }
    };

    uploaderInstance = new s3.FineUploader({
      ...baseConfig,
      element: uploaderElement[0],
      callbacks: {
        onSessionRequestComplete: () => {
          createPreviewBoxes();

          // Add click event to uploaded images
          wrapper.find("li.qq-upload-success").each(function () {
            let id = uploaderInstance.getId(this);
            $(this).on("click", function () {
              onSelect(uploaderInstance.getUploads({ id }));
            });
          });
        },
      },
      scaling: {
        hideScaled: true,
        sizes: [{ maxSize: 100 }],
      },
      validation: {
        sizeLimit: 50000,
        image: {
          maxHeight: 512,
          maxWidth: 512,
        },
      },
      signature: { endpoint: `${API_ROOT}/mapping/themes/media/sign` },
      uploadSuccess: { endpoint: `${API_ROOT}/mapping/themes/media` },
      deleteFile: { enabled: true, endpoint: `${API_ROOT}/mapping/themes/media` },
      session: {
        endpoint: `${API_ROOT}/mapping/themes/media`,
        params: { entityID: store.getState().app.entityID ?? 4 },
      },
    });

    wrapper.append(uploaderElement);
  }

  function onPreviewBoxClick(event) {
    selectedLineNumber = parseInt($(event.target).attr("line-number"));
    const position = $(event.target).position();
    if (position.left === 4) position.left = 46;
    uploaderElement.css(position);
    uploaderElement.css("display", "block");
  }
};
