import * as turf from "@turf/turf";
import { TILE_URL } from "../../configuration";
import { LabelLayer } from "./L.LabelLayer";

/**
 * An object with a variety of map-related method which a controller can use to interact with Leaflet
 * @param {object} map A instance of L.map
 * @return {void}
 */
export const TruMap = function TruMap(map, isPublicPage) {
  this._map = map;
  this._layers = {};
  this._uuid = 0;
  this._tileUrl = TILE_URL;
  this._tileLayerID = null;
  this.isPublicPage = isPublicPage;
  this._contextMenu = null;
  this._drawnItems = null;
  this._drawControl = null;
  this._drawOptionsToolbar = null;
  this._vectorLayer = null;
  this._shapeOverlay = null;

  this._map.attributionControl.addAttribution(
    '<a href="https://www.mapbox.com/about/maps/" target="_blank">© Mapbox</a> <a href="https://openstreetmap.org/about/" target="_blank">© OpenStreetMap</a> <a class="mapbox-improve-map" href="https://www.mapbox.com/map-feedback/#mapbox.streets/-76.880/38.880/11" target="_blank">Improve this map</a>'
  );
  this._map.attributionControl.setPrefix(""); // Remove flag
};

TruMap.prototype = {
  /**
   * Add a tile layer to the map
   * @param user     A Mapbox user to fetch the style from
   * @param styleID  A valid MapBox tile set style identifier
   * @param tileSize The size of the tiles. Either 256 or 512 pixels
   * @param highDPI  If true, use high dpi tiles
   * @param {string} accessToken MapBox access token
   * @return {void}
   */
  addMapBoxTileLayer: function (user, styleID, tileSize, highDPI, accessToken) {
    highDPI = highDPI === true || highDPI === "true" || highDPI === "TRUE" ? "@2x" : "";
    L.tileLayer(
      "https://api.mapbox.com/styles/v1/" +
        user +
        "/" +
        styleID +
        "/tiles/" +
        tileSize +
        "/{z}/{x}/{y}" +
        highDPI +
        "?access_token=" +
        accessToken,
      {
        maxZoom: 18,
      }
    ).addTo(this._map);
  },

  /**
   * Add a tile layer from Epiphany
   * @param {int} id
   * @return {void}
   */
  addOrUpdateTruTileLayer: function (id) {
    if (this._tileLayer) this._map.removeLayer(this._tileLayer);

    this._tileLayer = L.tileLayer(this._tileUrl + "/tile/{id}/{z}/{x}/{y}.png?request=" + new Date().getTime(), {
      maxZoom: 18,
      id: id,
    }).addTo(this._map);

    this._tileLayerID = id;

    return this._tileLayer;
  },

  /**
   * Register a Maplibre object for when vectors are present.
   */
  setVectorLayer(layer) {
    this._vectorLayer = layer;
  },

  /**
   * Add the draw control
   * @param {function} onCreate Creation callback
   * @param {function} onEdit Edit callback
   * @param {function} onDelete Deletion callback
   * @param {function} onRefresh Refresh callback
   */
  enableDrawing(onCreate, onEdit, onDelete, onRefresh) {
    if (!this._drawnItems) this._drawnItems = new L.FeatureGroup();

    this._map.addLayer(this._drawnItems);

    this._drawControl = new L.Control.Draw({
      draw: {
        polyline: { shapeOptions: {} },
        polygon: { shapeOptions: {} },
        rectangle: {
          showArea: true,
          metric: false,
          shapeOptions: {},
        },
        circle: {
          metric: "yards",
          shapeOptions: {},
        },
      },
      edit: {
        featureGroup: this._drawnItems,
      },
    });
    this._map.addControl(this._drawControl);
    this._drawOptionsToolbar = this._newDrawOptionsToolbar({ refresh: onRefresh });
    this._map.addControl(this._drawOptionsToolbar);

    // Setup callbacks
    this._map.on(L.Draw.Event.CREATED, (e) => {
      this._drawnItems.addLayer(e.layer);
    });
    this._map.on(L.Draw.Event.CREATED, create.bind(this));
    this._map.on(L.Draw.Event.EDITED, edit.bind(this));
    this._map.on(L.Draw.Event.DELETED, remove.bind(this));

    // On layer creation, setup the GeoJSON property.
    function create(e) {
      e.layer.feature = {
        type: "Feature",
        properties: { id: Date.now(), color: e.layer.options.color },
      };

      // If this is a circle, record its radius (since it otherwise will be indistinguishable from a point).
      if (e.layer instanceof L.Circle) {
        e.layer.feature.properties.radius = e.layer.options.radius;
      }

      if (typeof onCreate == "function") onCreate.call(null, e.layer.toGeoJSON());
    }

    // On layer edit, send an array of edited features.
    function edit(e) {
      e.layers.eachLayer((layer) => {
        if (layer instanceof L.Circle) {
          console.log(layer);
          // Have to create a complete new `feature` object for our changes to stick.
          layer.feature = {
            ...layer.feature,
            properties: { ...layer.feature.properties, radius: layer.getRadius() },
          };
        }
      });

      if (typeof onEdit == "function") onEdit.call(null, e.layers.toGeoJSON().features);
    }

    // On layer deletion, send an array of feature IDs.
    function remove(e) {
      if (typeof onDelete == "function") {
        let ids = _.map(e.layers.toGeoJSON().features, function (feature) {
          return feature.properties.id;
        });
        if (ids && ids.length) onDelete.call(null, ids);
      }
    }
  },

  /**
   * Remove the draw control
   */
  disableDrawing() {
    this._map.removeLayer(this._drawnItems);
    this._drawnItems = null;
    this._map.removeControl(this._drawControl);
    this._drawOptionsToolbar.remove();
    this._map.off(L.Draw.Event.CREATED);
    this._map.off(L.Draw.Event.EDITED);
    this._map.off(L.Draw.Event.DELETED);
  },

  /**
   * Given a GeoJSON layer, setup the drawnItems layer.
   * @param {array} features
   */
  setDrawnItems(features) {
    let newDrawn = new L.GeoJSON(features, {
      style: function (feature) {
        return { color: feature.properties.color, opacity: 0.5 };
      },
      // Make sure that points with radii are interpreted as circles.
      pointToLayer: function (feature, latlng) {
        if (feature.properties.radius != null) return L.circle(latlng, { radius: feature.properties.radius });
        else return new L.Marker(latlng);
      },
    });

    if (!this._drawnItems) {
      this._drawnItems = new L.FeatureGroup();
      this._map.addLayer(this._drawnItems);
    }

    // Index current layers by ID.
    let layersByID = {};
    this._drawnItems.getLayers().forEach(function (layer) {
      layersByID[layer.feature.properties.id] = layer;
    });

    // Add new layers, replacing current where ID matches.
    newDrawn.eachLayer((layer) => {
      if (layer.feature.properties.id in layersByID)
        this._drawnItems.removeLayer(layersByID[layer.feature.properties.id]);

      this._setupTextAnnotation(layer);

      // Forward events onto the MapLibre canvas so selection can still work over/through annotation shapes.
      this._forwardEventsToMapLibre(layer, ["mousedown", "mouseup", "mousemove"]);

      this._drawnItems.addLayer(layer);
    });
  },

  /**
   * Remove drawn items by ID.
   * @param {array} IDs
   */
  removeDrawnItems(IDs) {
    if (!this._drawnItems || !IDs) return;

    this._drawnItems.eachLayer((layer) => {
      if (IDs.indexOf(layer.feature.properties.id) != -1) this._drawnItems.removeLayer(layer);
    });
  },

  /**
   * Show text for annotations, and allow editing.
   * @param {object} layer
   */
  _setupTextAnnotation(layer) {
    if (!(layer instanceof L.Polygon) && !(layer instanceof L.Circle) && !(layer instanceof L.Rectangle)) return;

    layer.on("add", () => {
      let props = layer.feature.properties;
      layer.label = new LabelLayer(layer, {
        text: props.notes,
        fontSize: props.font_size || 26,
        enableEditing: !this.isPublicPage,
      });
      layer.label.addTo(this._map);
      layer.label.on("text-edit", (e) => {
        // Have to create a complete new `feature` object for our changes to stick.
        layer.feature = {
          ...layer.feature,
          properties: {
            ...layer.feature.properties,
            notes: e.text,
            font_size: e.fontSize,
          },
        };

        // Manually fire the L.Draw.Event.EDITED event.
        this._map.fire(L.Draw.Event.EDITED, { layers: new L.LayerGroup([layer]) });
      });
    });

    layer.on("remove", () => {
      layer.label.remove();
    });
  },

  /**
   * Copy incoming events of the specified types and pass them to the given callback.
   */
  _forwardEventsToMapLibre(layer, eventTypes) {
    for (const type of eventTypes) {
      layer.on(type, (e) => {
        if (this._vectorLayer) {
          const event = new MouseEvent(type, {
            screenX: e.originalEvent.screenX,
            screenY: e.originalEvent.screenY,
            clientX: e.originalEvent.clientX,
            clientY: e.originalEvent.clientY,
            ctrlKey: e.originalEvent.ctrlKey,
            shiftKey: e.originalEvent.shiftKey,
            altKey: e.originalEvent.altKey,
            metaKey: e.originalEvent.metaKey,
            button: e.originalEvent.button,
          });

          this._vectorLayer.getMaplibreMap().getCanvasContainer().dispatchEvent(event);
        }
      });
    }
  },

  /**
   * Enable the menu to open when the map is right-clicked
   */
  enableContextMenu() {
    this._map.on("contextmenu", this.openContextMenu.bind(this));
    this._map.on("click", this.closeContextMenu.bind(this));
  },

  openContextMenu(e, customOptions = [], searchText = {}, clickedOnSearchPin = false) {
    if (this._contextMenu) {
      this._map.removeLayer(this._contextMenu);
    }
    const position = {
      clientX: e.originalEvent.clientX,
      clientY: e.originalEvent.clientY,
      mapX: e.layerPoint.x,
      mapY: e.layerPoint.y,
    };
    this._contextMenu = new L.ContextMenu(e.latlng, position, this, customOptions, searchText, clickedOnSearchPin);
    this._map.addLayer(this._contextMenu);
    this._map.on("click", this.closeContextMenu.bind(this));
  },

  /**
   * Close any context menu that's open
   */
  closeContextMenu() {
    if (this._contextMenu) {
      this._map.removeLayer(this._contextMenu);
      this._contextMenu = null;
    }
  },

  /**
   * Remove a collection from the map
   * @param  {string} id Unique ID as generated by uniqueId()
   * @return {void}
   */
  removeCollection: function (id) {
    this.removeLayer(id);
  },

  /**
   * Check whether a collection is on the map
   * @param {string} id
   * @return {bool}
   */
  hasCollection: function (id) {
    return id in this._layers;
  },

  /**
   * Add a single point to the map
   * @param {array} center lat/lon
   * @param {function} makeMenu
   * @return {string} Unique ID for layer
   */
  addMarker: function (center, searchText = {}) {
    let id = this.uniqueId(),
      layer = L.marker(center, { icon: L.divIcon({ className: "leaflet-marker", iconSize: [40, 40] }) });

    let options = [
      {
        name: "Remove marker",
        onSelect: () => {
          this.closeContextMenu();
          this.removeLayer(id);
        },
      },
    ];

    this._layers[id] = layer;
    layer.addTo(this._map);
    layer.on("contextmenu", (e) => this.openContextMenu(e, options, searchText, true));

    return id;
  },

  /**
   * Remove a layer from the map
   * @param  {string} id Unique ID as generated by uniqueId()
   * @return {vodi}
   */
  removeLayer: function (id) {
    if (id in this._layers) {
      this._map.removeLayer(this._layers[id]);
      delete this._layers[id];
    }
  },

  /**
   * Closes a popup
   * @param {object} popup
   */
  closePopup: function (popup) {
    return this._map.closePopup(popup);
  },

  /**
   * Adds a scale indicator to the map
   */
  addScale: function () {
    this._scaleIndicator = L.control.scale({ metric: false, position: "bottomright" });
    this._scaleIndicator.addTo(this._map);
  },

  /**
   * Removes the scale indicator from the map
   */
  removeScale: function () {
    this._map.removeControl(this._scaleIndicator);
  },

  /**
   * Remove all layers from the map
   * @param {array} List of layer IDs not to clear.
   * @return {void}
   */
  clear: function (except) {
    for (var id in this._layers) {
      if (except && except.includes(id)) {
        continue;
      }

      this._map.removeLayer(this._layers[id]);
      delete this._layers[id];
    }

    if (this._tileLayer) this._map.removeLayer(this._tileLayer);

    if (this._drawnItems) {
      this.disableDrawing();
      this._drawnItems = null;
    }
  },

  /**
   * Set map center
   * @param {array} center lat/lon
   * @return {void}
   */
  setCenter: function (center) {
    this._map.setView(center);
  },

  /**
   * Gets the map's center
   * @return {}
   */
  getCenter: function () {
    return this._map.getCenter();
  },

  /**
   * Get the raster tile URL
   */
  getTileUrl: function () {
    return this._tileUrl;
  },

  /**
   * Get the raster tile layer ID
   */
  getTileLayerID: function () {
    return this._tileLayerID;
  },

  /**
   * Gets the map's current zoom
   * @return {}
   */
  getZoom: function () {
    return this._map.getZoom();
  },

  /**
   * Set map zoom
   * @param {int} zoom
   * @return {void}
   */
  setZoom: function (zoom, animate) {
    var animate = !!animate;
    this._map.setZoom(zoom, { animate: animate });
  },

  /**
   * Set map center and zoom
   * @param {array} center lat/lon
   * @param {int} zoom
   * @param {object} options
   * @return {void}
   */
  setView: function (center, zoom, options) {
    this._map.setView(center, zoom, options);
  },

  /**
   * Returns the Leaflet map object
   * @return {object}
   */
  getLeafletMap: function () {
    return this._map;
  },

  /**
   * Reset's the map size
   */
  invalidateSize: function () {
    this._map.invalidateSize();
  },

  /**
   * Create a unique ID for a layer
   * @return {string}
   */
  uniqueId: function () {
    return "id" + ++this._uuid;
  },

  /**
   * Zoom/pan as necessary to fit the given features into the maps view.
   * @param {*} features
   */
  goTo: function (features) {
    const bounds = turf.bbox(turf.featureCollection(features.map((f) => f.toJSON())));
    this._map.flyToBounds(
      [
        [bounds[1], bounds[0]],
        [bounds[3], bounds[2]],
      ],
      { duration: 0.75, animate: true }
    );
  },

  setShapeOverlay(feature) {
    if (this._shapeOverlay) {
      this._map.removeLayer(this._shapeOverlay);
    }

    if (feature) {
      this._shapeOverlay = L.geoJSON(feature, { smoothFactor: 0.1 });
      this._shapeOverlay.addTo(this._map);
    }
  },

  /**
   * Make a toolbar for additional draw controls, like color and refresh.
   * @param {object} options
   * @return {return}
   */
  _newDrawOptionsToolbar(options) {
    // Prep a function to change the color for leaflet-draw.
    let setColor = (color) => {
      this._drawControl.options.draw.polyline.shapeOptions.color = color;
      this._drawControl.options.draw.polygon.shapeOptions.color = color;
      this._drawControl.options.draw.rectangle.shapeOptions.color = color;
      this._drawControl.options.draw.circle.shapeOptions.color = color;
    };

    let toolbar = L.Control.extend({
      options: {
        position: "topleft",
        colors: ["#d61e42", "#1b8a3b", "#fcb232", "#0884c2", "#040384"],
        color: "#d61e42",
      },

      _isPaletteOpen: false,

      onAdd() {
        let div = L.DomUtil.create("div", "leaflet-bar leaflet-control-draw-options");
        let colorBtn = $(
          `<a href="#" title="Pick Color" class="color"><span style="background-color: ${this.options.color}"></span></a>`
        );
        let refreshBtn = $('<a href="#" title="Refresh Annotations" class="refresh"></a>');

        colorBtn.click(this._togglePalette.bind(this)).appendTo(div);
        refreshBtn.click(this.options.refresh).appendTo(div);

        setColor(this.options.color);

        return div;
      },

      _openPalette() {
        this._isPaletteOpen = true;
        if ($(this._container).find("ul.colors").length) return;

        let actions = $('<ul class="colors leaflet-draw-actions leaflet-draw-actions-bottom"></ul>');
        actions.css({ width: this.options.colors.length * 24 });
        for (let i = 0; i < this.options.colors.length; i++) {
          let color = this.options.colors[i];
          let li = $(`<li style="background-color:${color}" data-color=${color}></li>`);
          li.click(this._setColor.bind(this));
          actions.append(li);
        }
        $(this._container).append(actions);
      },

      _closePalette() {
        this._isPaletteOpen = false;
        $(this._container).find("ul.colors").remove();
      },

      _togglePalette() {
        if (this._isPaletteOpen) {
          this._closePalette();
        } else {
          this._openPalette();
        }
      },

      _setColor(e) {
        this.options.color = e.target.getAttribute("data-color");
        $(this._container).find("a span").css({ "background-color": this.options.color });
        this._closePalette();
        setColor(this.options.color);
      },
    });

    return new toolbar(options);
  },
};

return TruMap;
