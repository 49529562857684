export class StorageService {
  prefix = "epiphany-analytics-app";
  supported = false;
  storage: Storage | storagePolyfill;
  parsedStorage: Record<string, string | number | number[] | Record<string, number>> = {};

  constructor() {
    this.supported = "localStorage" in window && window["localStorage"] !== null ? true : false;

    // When Safari (OS X or iOS) is in private browsing mode, it appears as though localStorage
    // is available, but trying to call .setItem throws an exception below:
    // "QUOTA_EXCEEDED_ERR: DOM Exception 22: An attempt was made to add something to storage that exceeded the quota."
    // https://github.com/gsklee/ngStorage/blob/master/ngStorage.js#L59
    if (this.supported) {
      const key = "__" + Math.round(Math.random() * 1e7);

      try {
        window.localStorage.setItem(key, key);
        window.localStorage.removeItem(key);
      } catch {
        this.supported = false;
      }
    }

    if (this.supported) {
      this.storage = window.localStorage;
      this.parsedStorage = JSON.parse(window.localStorage.getItem(this.prefix) ?? "{}");
    } else this.storage = new storagePolyfill();
  }

  getItem(key: string, entityID: number) {
    return this.parsedStorage[this._getKey(key, entityID)] || null;
  }

  setItem(key: string, value: string | number | number[] | Record<string, number>, entityID: number) {
    this.parsedStorage[this._getKey(key, entityID)] = value;
    this._setStorage();
  }

  removeItem(key: string, entityID: number) {
    delete this.parsedStorage[this._getKey(key, entityID)];
    this._setStorage();
  }

  clear() {
    this.parsedStorage = {};
    this._setStorage();
  }

  _setStorage() {
    this.storage.setItem(this.prefix, JSON.stringify(this.parsedStorage));
  }

  _getKey(key: string, entityID?: number) {
    if (entityID) key = "entity." + entityID + "." + key;

    return key;
  }
}

class storagePolyfill {
  data: Record<string, string> = {};

  constructor() {}

  getItem(key: string) {
    return this.data[key] || null;
  }

  setItem(key: string, value: string) {
    this.data[key] = value;
  }

  removeItem(key: string) {
    delete this.data[key];
  }
}

export const StorageServiceInstance = new StorageService();
