import { useMemo } from "react";

export const DatePicker = ({
  onChange,
  disabled,
  min: externalMin,
  max: externalMax,
  value: externalValue,
}: {
  onChange: (d: Date | undefined) => void;
  disabled?: boolean;
  min?: Date;
  max?: Date;
  value: Date | undefined;
}) => {
  const value = useMemo(() => {
    if (!externalValue) return;
    return externalValue.toISOString().split("T")[0];
  }, [externalValue]);

  const min = useMemo(() => {
    if (!externalMin) return;
    return externalMin.toISOString().split("T")[0];
  }, [externalMin]);

  const max = useMemo(() => {
    if (!externalMax) return;
    return externalMax.toISOString().split("T")[0];
  }, [externalMax]);

  return (
    <input
      type="date"
      onChange={(e) => {
        if (e.target.value) {
          onChange(new Date(e.target.value));
        } else {
          onChange(undefined);
        }
      }}
      disabled={disabled}
      min={min}
      max={max}
      value={value ?? ""}
    />
  );
};
