/* eslint-disable react/prop-types */
import React, { Component, ReactNode } from "react";
import { ErrorBoundaryModal } from "./ErrorBoundaryModal";

interface ErrorBoundaryProps {
  children: ReactNode;
  fallback?: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
}

export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: undefined };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <ErrorBoundaryModal
            error={this.state.error}
            onClose={() => this.setState({ hasError: false, error: undefined })}
          />
          {this.props.fallback}
        </>
      );
    }
    return this.props.children;
  }
}

export function withErrorBoundary<T extends object>(Component: React.ComponentType<T>, fallback?: React.ReactNode) {
  const WrappedComponent = (props: T) => (
    <ErrorBoundary fallback={fallback}>
      <Component {...props} />
    </ErrorBoundary>
  );

  WrappedComponent.displayName = `withErrorBoundary(${Component.displayName || Component.name})`;

  return WrappedComponent;
}
