import moment, { Moment } from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ICollection } from "../../types/ICollection";
import { createDateRange, createMaxDateRange } from "../../data/map";
import LHistorySlider from "./HistorySlider/L.History-slider";

// eventually move this to L.HistorySlider
interface ILHistorySlider {
  remove: () => void;
  initialize: (options: {
    minDate?: Moment;
    maxDate?: Moment;
    range?: { minDate: Moment; maxDate: Moment };
    mode: number;
    interval: number;
  }) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (event: string, callback: (data: any) => void) => void;
  addTo: (map: L.Map) => void;
  options: {
    minDate?: Moment;
    maxDate?: Moment;
    range?: { minDate: Moment; maxDate: Moment };
    mode: number;
    interval: number;
  };
}

interface HistorySliderProps {
  leafletMap: L.Map;
  onChange: (range: { minDate: Moment; maxDate: Moment }) => void;
}

const HistorySlider = ({ leafletMap, onChange }: HistorySliderProps) => {
  const [control, setControl] = useState<ILHistorySlider>();

  const {
    map,
    map: { sliderType: mode, sliderInterval: interval, dateEnabled } = {
      sliderType: 0,
      sliderInterval: 0,
      dateEnabled: false,
    },
    availableCollections,
  } = useAppSelector((state) => state.pages.truterritory);

  const maxDateRange = useMemo(() => {
    if (!map) {
      return { minDate: moment(), maxDate: moment() };
    }

    const collections =
      map?.collectionIDs
        .map((id) => availableCollections.find((c) => c.ID == id))
        .filter((c): c is ICollection => !!c) || [];
    return createMaxDateRange(map, collections);
  }, [map, availableCollections]);

  const dateRange = useMemo(() => {
    if (!map) {
      return { minDate: moment(), maxDate: moment() };
    }

    return createDateRange(map);
  }, [map]);

  const rangesAreEqual = (
    range1?: { minDate?: Moment; maxDate?: Moment },
    range2?: { minDate?: Moment; maxDate?: Moment }
  ) => {
    return (
      range1?.minDate?.format() === range2?.minDate?.format() && range1?.maxDate?.format() === range2?.maxDate?.format()
    );
  };

  // initialize the control when the component is mounted
  useEffect(() => {
    if (control) {
      control.remove();
      setControl(undefined);
    }

    if (!dateEnabled) return;

    initialize();
    updateControlSettings();

    // Cleanup when the component is unmounted
    return () => {
      control?.remove();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateEnabled]);

  const initialize = () => {
    const control = LHistorySlider() as unknown as ILHistorySlider;
    setControl(control);
    control.on("change", (data: { range: { minDate: Moment; maxDate: Moment } }) => {
      // Update the dateRange when the slider changes
      onChange(data.range);
    });
    control.addTo(leafletMap);
  };

  const updateControlSettings = async () => {
    if (!control || !(dateRange && maxDateRange)) return;

    control.initialize({
      minDate: dateRange?.minDate ?? maxDateRange?.minDate,
      maxDate: dateRange?.maxDate ?? maxDateRange?.maxDate,
      range: {
        minDate: maxDateRange?.minDate ?? moment(),
        maxDate: maxDateRange?.maxDate ?? moment(),
      },
      mode,
      interval,
    });
  };

  // this is used when the control is initialized to set the options
  useEffect(() => {
    if (control && !control.options.range?.minDate) {
      updateControlSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [control]);

  // Update the slider to reflect the mode setting
  useEffect(() => {
    if (mode && control && mode !== control.options.mode) {
      control.remove();
      initialize();
      updateControlSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  // Update the slider if needed when maxDateRange or interval changes
  useEffect(() => {
    if (!rangesAreEqual(maxDateRange, control?.options.range) || interval != control?.options.interval) {
      updateControlSettings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxDateRange, interval]);

  return <></>;
};
export default HistorySlider;
