import { useEffect, useMemo, useState } from "react";
import moment from "moment";

import { Modal } from "../../components/Modal";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ITruMap } from "../../types/ITruMap";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { Checkbox } from "../../design/Checkbox";
import {
  useFetchExpiringUUIDMutation,
  useGeneratePasswordMutation,
  useGetAnnotationsOptionQuery,
  useGetNewUUIDMutation,
  useSaveMapPasswordMutation,
} from "../../slices/apiSlice";
import { setMap } from "../../slices/pagesSlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useHasMapAccess } from "./hasMapAccess";
import { SHARING_ROOT } from "../../configuration";
import { DatePicker } from "../../components/DatePicker";

export const PermalinkModal = ({
  isOpen,
  onClose,
  truMap,
}: {
  isOpen: boolean;
  onClose: () => void;
  truMap?: ITruMap;
}) => {
  const dispatch = useAppDispatch();

  const [fetchExpiringUUID] = useFetchExpiringUUIDMutation();
  const [getNewUUID] = useGetNewUUIDMutation();
  const [fetchNewPassword] = useGeneratePasswordMutation();
  const [saveMapPassword] = useSaveMapPasswordMutation();

  const { map } = useAppSelector((state) => state.pages.truterritory);

  const hasMapAccess = useHasMapAccess();

  const [saving, setSaving] = useState(false);
  const [confirming, setConfirming] = useState(false);

  const [oldPassword, setOldPassword] = useState(map?.password);
  const [password, setPassword] = useState(map?.password);
  const [generating, setGenerating] = useState(false);
  const { data: annotationsOption } = useGetAnnotationsOptionQuery(
    map?.createdAt ? moment(map.createdAt).format("YYYY-MM-DD") : skipToken
  );

  const [expiresAt, setExpiresAt] = useState<Date>();
  const [options, setOptions] = useState({
    useCenterZoom: false,
    showAnnotations: false,
    requirePassword: !!map?.password,
    expires: false,
    annotationsOption: null as null | string,
    expiringUUID: null as null | string,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const link = useMemo(generateLink, [options, annotationsOption, map]);

  /**
   * Create a URL from the map's UUID
   */
  function generateLink(): string {
    if (!map) return "";

    const uuid = (options.expires && options.expiringUUID) || map.UUID;
    const params = new URLSearchParams();

    if (options.showAnnotations && annotationsOption) {
      params.set("o", annotationsOption);
    }

    if (options.useCenterZoom && truMap) {
      const { lat, lng } = truMap.getCenter();
      params.set("lat", lat.toString());
      params.set("lng", lng.toString());
      params.set("zoom", truMap.getZoom().toString());
    }

    return SHARING_ROOT + "/" + uuid + (params.size ? "?" + params.toString() : "");
  }

  // Tell the server to create a new UUID
  const reset = function () {
    if (!map) return;

    setSaving(true);
    getNewUUID(map.ID)
      .unwrap()
      .then((UUID: string) => {
        setSaving(false);
        setConfirming(false);

        setOptions({ ...options, expires: false, expiringUUID: null });
        setExpiresAt(undefined);
        dispatch(setMap({ ...map, UUID }));
      });
  };

  // Retrieve a temporary, expiring UUID
  const getExpiringUUID = function (expiresAt: Date) {
    if (!map) return;

    setOptions({ ...options, expiringUUID: null });
    fetchExpiringUUID({ ID: map.ID, expiresAt })
      .unwrap()
      .then((uuid: string) => {
        setOptions({ ...options, expiringUUID: uuid });
      });
  };

  // Set a new password
  const applyPassword = function () {
    if (!map) return;

    // If password is required but unchanged, do nothing.
    if (password === oldPassword && options.requirePassword) return;

    let newPassword = password;

    // If password is required but has been changed to empty, un-require it.
    if (!password && options.requirePassword) {
      setOptions({ ...options, requirePassword: false });
    }

    // If password is not required but still set, clear it.
    else if (password && !options.requirePassword) {
      setPassword(null);
      newPassword = null;
    }

    setOldPassword(password);
    saveMapPassword({ ID: map.ID, password: newPassword || null });
    dispatch(setMap({ ...map, password: newPassword || null }));
  };

  // Generate a password
  const generatePassword = function () {
    if (!map) return;
    setGenerating(true);

    fetchNewPassword()
      .unwrap()
      .then((data) => {
        setOldPassword(password);
        setPassword(data);
        setGenerating(false);
      });
  };

  useEffect(() => {
    if (options.expires && expiresAt) {
      getExpiringUUID(expiresAt);
    } else {
      setOptions({ ...options, expiringUUID: null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.expires, expiresAt]);

  useEffect(() => {
    if (map?.password !== oldPassword) {
      setOldPassword(map?.password);
      setPassword(map?.password);
      setOptions({ ...options, requirePassword: !!map?.password });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map?.password]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="truterritory-save-permalink-modal">
      <div className="modal-body">
        <h4>Permalink:</h4>
        {/* <pre>{JSON.stringify(linkOptions, null, 2)}</pre> */}
        <input
          className="link"
          type="text"
          value={link ?? ""}
          readOnly
          disabled={
            (options.expires && !options.expiringUUID) || (options.showAnnotations && !options.annotationsOption)
          }
          onFocus={(e) => e.target.select()}
        />
        <Checkbox
          name="useCenterZoom"
          id="useCenterZoom"
          label="Use current center and zoom"
          checked={options.useCenterZoom}
          onChange={(e) => setOptions({ ...options, useCenterZoom: e.target.checked })}
        />
        <Checkbox
          name="showAnnotations"
          id="showAnnotations"
          label="Show annotations"
          checked={options.showAnnotations}
          onChange={(e) => setOptions({ ...options, showAnnotations: e.target.checked })}
        />
        <div className="expires">
          <Checkbox
            name="expires"
            id="expires"
            label="Expires At"
            checked={options.expires}
            onChange={(e) => setOptions({ ...options, expires: e.target.checked })}
          />
          <DatePicker
            onChange={(d) => {
              setExpiresAt(d);
            }}
            min={moment().add(1, "days").toDate()}
            max={moment().add(2, "years").toDate()}
            value={expiresAt}
            disabled={!options.expires}
          />
        </div>
        {hasMapAccess("update") && (
          <>
            <div className="password">
              <Checkbox
                name="requirePassword"
                id="requirePassword"
                label="Require Password"
                checked={options.requirePassword}
                onChange={(e) => setOptions({ ...options, requirePassword: e.target.checked })}
              />
              <input
                type="text"
                value={password ?? ""}
                disabled={!options.requirePassword}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button className="btn generate" disabled={generating} onClick={generatePassword} />
              <button
                className="btn light-blue apply"
                onClick={applyPassword}
                disabled={
                  (!options.requirePassword && !password) || (options.requirePassword && password == oldPassword)
                }
              >
                Apply
              </button>
            </div>
          </>
        )}
        {!hasMapAccess("update") && !!map?.password && (
          <div className="password">
            <p>
              Password:<em> {map.password} </em>
            </p>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <div className="controls">
          {hasMapAccess("update") && (
            <div>
              {confirming && (
                <p className="text-danger">Generating a new link will break the previous link. Continue?</p>
              )}
              {!confirming && (
                <button className="btn reset" onClick={() => setConfirming(true)}>
                  Generate New Link
                </button>
              )}
              {confirming && (
                <>
                  <button className="btn confirm" onClick={reset} disabled={saving}>
                    Continue
                  </button>
                  <button className="btn cancel" onClick={() => setConfirming(false)} disabled={saving}>
                    Cancel
                  </button>
                </>
              )}
            </div>
          )}
          <button className="btn light-blue" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};
