import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Modal } from "./Modal";
import { useAppSelector } from "../hooks/useAppSelector";
import * as Yup from "yup";
import { useLazyForgotPasswordQuery, useLazyLoginQuery } from "../slices/apiSlice";
import { setSessionAndClearError } from "../slices/appSlice";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { useDynamicFormik } from "../hooks/useDynamicFormik";

export const LoginModal = () => {
  const dispatch = useAppDispatch();
  const { error: globalError } = useAppSelector((state) => state.app);
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [didForgetPassword, setDidForgetPassword] = useState(false);

  const [handleLogin, { error: loginError }] = useLazyLoginQuery();

  const isLoginPage = useMemo(() => pathname === "/login", [pathname]);

  const { errors, isSubmitting, handleSubmit, handleChange } = useDynamicFormik({
    initialValues: {
      uname: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      uname: Yup.string().required("Please enter a username"),
      password: Yup.string().required("Please enter a password"),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const loginResponse = await handleLogin(values).unwrap();
        dispatch(setSessionAndClearError(loginResponse));

        setSubmitting(false);
        setIsModalOpen(false);
      } catch {
        // this wil be caught by the error handler
        setSubmitting(false);
      }
    },
  });

  return (
    <Modal isOpen={isModalOpen} onClose={() => {}} className="error-handler-modal">
      {!didForgetPassword ? (
        <>
          <div className="modal-header">{isLoginPage ? <h3>Login:</h3> : <h3>You are not logged in</h3>}</div>
          <form name="loginForm" onSubmit={handleSubmit}>
            <div className="modal-body">
              {!isLoginPage && <p>Please login below:</p>}
              <input type="text" onChange={handleChange} name="uname" placeholder="Username" autoFocus />
              <input type="password" onChange={handleChange} name="password" placeholder="Password" />
            </div>
            <div className="modal-footer">
              <small className="forgot-password" onClick={() => setDidForgetPassword(true)}>
                I forgot my password
              </small>
              {isSubmitting ? (
                <button className="btn orange" disabled>
                  <span className="small-loading-indicator"></span>
                  <span>Logging you in...</span>
                </button>
              ) : (
                <input className="btn orange" type="submit" value="Login" />
              )}
            </div>
          </form>
          {globalError?.message !== "Login Required" || Object.values(errors).length || loginError ? (
            <div className="modal-addendum text-danger">
              {globalError?.message && globalError?.message !== "Login Required" && <p>{globalError.message}</p>}
              {errors && Object.entries(errors).map(([k, error]) => <p key={`error.${k}`}>{`${error}`}</p>)}
              {loginError && (
                <p>{(loginError as { data?: { status?: { message?: string } } }).data?.status?.message}</p>
              )}
            </div>
          ) : null}
        </>
      ) : (
        <ForgotPasswordForm onGoBack={() => setDidForgetPassword(false)} />
      )}
    </Modal>
  );
};

const ForgotPasswordForm = ({ onGoBack }: { onGoBack: () => void }) => {
  const [requestEmail, { error, data: successResponse }] = useLazyForgotPasswordQuery();

  const { isSubmitting, handleSubmit, handleChange } = useDynamicFormik({
    initialValues: { uname: "" },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        await requestEmail(values).unwrap();
        setSubmitting(false);
      } catch {
        setSubmitting(false);
      }
    },
  });

  return (
    <form id="forgot-password-form" name="forgotPasswordForm" onSubmit={handleSubmit}>
      <div className="modal-header">
        <h3>Password Reset:</h3>
        <h4>Please enter your email or username</h4>
      </div>
      <div className="modal-body">
        <p>If your credentials match, we&apos;ll send an email with further instructions.</p>
        <input type="text" required onChange={handleChange} name="uname" placeholder="Email or Username" />
      </div>
      <div className="modal-footer">
        <span className="btn" onClick={onGoBack}>
          Back to Login
        </span>

        {isSubmitting ? (
          <button className="btn orange" disabled>
            <span className="small-loading-indicator"></span>
            <span>Sending Email...</span>
          </button>
        ) : !successResponse?.status?.message ? (
          <input className="btn orange" type="submit" value="Request Email" />
        ) : null}
      </div>
      {error && (
        <div className="modal-addendum text-danger">
          {Object.entries(
            (
              (error as FetchBaseQueryError)?.data as {
                meta?: {
                  errors: {
                    [key: string]: string[];
                  };
                };
              }
            )?.meta?.errors || {}
          ).map(([exception, message]) => (
            <p key={exception}>{message}</p>
          ))}
          <p>{((error as FetchBaseQueryError).data as { status: { message: string } })?.status?.message}</p>
        </div>
      )}
      {successResponse?.status?.message && (
        <div className="modal-addendum text-success">
          <p>{successResponse?.status?.message}</p>
        </div>
      )}
    </form>
  );
};
