import config from "../configuration";

interface IGoogleGeocoderResponse {
  results?: {
    geometry?: {
      location?: {
        lat: number;
        lng: number;
      };
    };
    formatted_address: string;
  }[];
}

type GeocoderResult = {
  location?: {
    lat: number;
    lng: number;
  };
  address?: string;
};

export async function geocode(address: string): Promise<GeocoderResult> {
  const resp = await fetch(
    "https://maps.googleapis.com/maps/api/geocode/json?" +
      new URLSearchParams({ address: address, key: config.GEOCODE_KEY }),
    {
      credentials: "omit",
      mode: "cors",
    }
  );

  const body = (await resp.json()) as IGoogleGeocoderResponse;
  if (!body?.results?.length) {
    return {};
  }

  return {
    location: body.results[0].geometry?.location,
    address: body.results[0].formatted_address,
  };
}
