import { useNavigate, useParams } from "react-router-dom";
import { useCreateEntityMutation, useGetEntityQuery, useUpdateEntityMutation } from "../../slices/apiSlice";
import { useDynamicFormik } from "../../hooks/useDynamicFormik";
import * as Yup from "yup";
import { useEffect, useMemo } from "react";
import moment from "moment";

export const EditEntity = () => {
  const navigate = useNavigate();
  const { entityID } = useParams<{ entityID: string }>();
  const isEditMode = Boolean(entityID);

  const [update, { isLoading: isSavingEntity }] = useUpdateEntityMutation();
  const [create, { isLoading: isCreatingEntity }] = useCreateEntityMutation();

  const { data: entity } = useGetEntityQuery(parseInt(entityID ?? ""), {
    skip: !isEditMode,
  });

  const { values, errors, handleChange, handleSubmit, setValues } = useDynamicFormik({
    initialValues: {
      name: "",
      address: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      website: "",
      phone: "",
      ...entity,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
    }),
    onSubmit: async (values) => {
      if (isEditMode) {
        await update({ ID: parseInt(entityID ?? ""), ...values });
      } else {
        await create({ ...values });
      }
      navigate("/admin/users-and-entities");
    },
  });

  useEffect(() => {
    if (entity && isEditMode) {
      setValues({ ...entity });
    }
  }, [entity, isEditMode, setValues]);

  return (
    <div className="entity-details">
      <div className="manage-header">Entity Details</div>
      <div className="manage-body">
        {errors && (
          <div className="text-danger">
            {Object.entries(errors).map((error) => (
              <span key={`error-${error}`}>{error}</span>
            ))}
          </div>
        )}
        <div className="col-md-12">
          <form name="entityForm" onSubmit={handleSubmit}>
            <label htmlFor="name">Name:</label>
            <input
              id="name"
              type="text"
              placeholder="NAME"
              value={values.name ?? ""}
              name="name"
              onChange={handleChange}
            />
            <label htmlFor="address">Address:</label>
            <input
              id="address"
              type="text"
              placeholder="ADDRESS"
              value={values.address ?? ""}
              name="address"
              onChange={handleChange}
            />
            <label htmlFor="city">City:</label>
            <input
              id="city"
              type="text"
              placeholder="CITY"
              value={values.city ?? ""}
              name="city"
              onChange={handleChange}
            />
            <label htmlFor="state">State:</label>
            <input
              id="state"
              type="text"
              placeholder="STATE"
              value={values.state ?? ""}
              name="state"
              onChange={handleChange}
            />
            <label htmlFor="zip">Zip:</label>
            <input id="zip" type="text" placeholder="ZIP" value={values.zip ?? ""} name="zip" onChange={handleChange} />
            <label htmlFor="country">Country:</label>
            <input
              id="country"
              type="text"
              placeholder="COUNTRY"
              value={values.country ?? ""}
              name="country"
              onChange={handleChange}
            />
            <label htmlFor="website">Website:</label>
            <input
              id="website"
              type="text"
              placeholder="WEBSITE"
              value={values.website ?? ""}
              name="website"
              onChange={handleChange}
            />
            <label htmlFor="phone">Phone:</label>
            <input
              id="phone"
              type="text"
              placeholder="PHONE"
              value={values.phone ?? ""}
              name="phone"
              onChange={handleChange}
            />
            <h5 className="col-md-12">Fiscal year start:</h5>
            <MonthSelector
              value={values.fyMonth}
              onChange={(value) => handleChange({ target: { name: "fyMonth", value } })}
            />
            <DaySelector
              month={values.fyMonth}
              value={values.fyDay}
              onChange={(value) => handleChange({ target: { name: "fyDay", value } })}
            />
            <div className="controls">
              <span className="btn" onClick={() => navigate("/admin/users-and-entities")}>
                Close
              </span>
              {isSavingEntity || isCreatingEntity ? (
                <span className="btn disabled">
                  <span className="small-loading-indicator"></span>
                  <span>Saving...</span>
                </span>
              ) : (
                <input className="btn" type="submit" value="Save" />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const MonthSelector = ({ value, onChange }: { onChange: (value: number) => void; value?: number }) => {
  const months = useMemo(() => moment.months(), []);

  return (
    <div className="select-holder">
      <select value={value ?? ""} name="fyMonth" onChange={(e) => onChange(Number(e.target.value))}>
        <option value="" disabled>
          Select month
        </option>
        {months.map((month, index) => (
          <option key={month} value={index + 1}>
            {month}
          </option>
        ))}
      </select>
    </div>
  );
};

/**
 * Returns an array of day numbers (e.g., [1, 2, 3, ..., 30])
 * for the specified 1-based month (1 = January, 12 = December).
 * If no month is provided (or it's 0), returns an empty array.
 */
export function getMonthDays(month?: number): number[] {
  if (!month) {
    return [];
  }

  // moment({ month: month - 1, day: 1 }) sets the date to the first
  // day of the given month. endOf('month') moves it to the last day
  // of that month, and .date() returns the day number (e.g., 30, 31, 28).
  const daysInMonth = moment({ month: month - 1, day: 1 })
    .endOf("month")
    .date();

  // Create an array of day numbers from 1..daysInMonth.
  return Array.from({ length: daysInMonth }, (_, i) => i + 1);
}

const DaySelector = ({
  value,
  onChange,
  month,
}: {
  month?: number;
  onChange: (value: number) => void;
  value?: number;
}) => {
  const days = getMonthDays(month);

  return (
    <div className="select-holder">
      <select value={value ?? ""} name="fyDay" onChange={(e) => onChange(Number(e.target.value))}>
        <option value="" disabled>
          Select day
        </option>
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </select>
    </div>
  );
};
