import { useMemo } from "react";
import { useAppSelector } from "./useAppSelector";
import { PermissionManager } from "../services/permission-manager";
import { ISession } from "../types/ISession";
import { IEntity } from "../types/IEntity";

const routes = [
  {
    id: "mapping",
    name: "TruTerritory",
    sections: [],
  },
  {
    id: "data",
    name: "Data",
    sections: [],
  },
  {
    id: "admin",
    name: "Admin",
    visible: false,
    sections: [
      {
        text: "Manage",
        routes: [
          {
            id: "admin.users-entities",
            sref: "/admin/users-and-entities",
            name: "Users & Entities",
          },
        ],
      },
    ],
  },
];

type ComputedRoutes = {
  enabled: boolean;
  sections: {
    routes: {
      enabled: boolean;
      id: string;
      sref: string;
      name: string;
    }[];
    text: string;
  }[];
  id: string;
  name: string;
  visible?: boolean;
}[];

export const useMenu = () => {
  const { entityID, session } = useAppSelector((state) => state.app);

  const computedRoutes: ComputedRoutes = useMemo(() => {
    return computeRoutes(entityID, session);
  }, [entityID, session]);

  const enabled = useMemo(() => computedRoutes.filter((route) => route.enabled), [computedRoutes]);

  const first = useMemo(() => {
    return getFirstRoute(computedRoutes, entityID);
  }, [computedRoutes, entityID]);

  return { routes: computedRoutes, first, enabled };
};

export const computeRoutes = (entityID?: IEntity["ID"], session?: ISession) => {
  const permissionManager = new PermissionManager(session?.permissions);

  return routes.map((primary) => ({
    ...primary,
    enabled: permissionManager.hasMenuAccess("entity" + "." + entityID + "." + primary.id),
    sections: primary.sections.map((section) => ({
      ...section,
      routes: section.routes.map((secondary) => ({
        ...secondary,
        enabled: permissionManager.hasMenuAccess("entity" + "." + entityID + "." + secondary.id),
      })),
    })),
  }));
};

export const getFirstRoute = (routes: ComputedRoutes, entityID?: IEntity["ID"]) => {
  const returnRoute: {
    route: string;
    params: { entityID?: number; noAccess?: boolean };
  } = {
    route: "dashboard",
    params: { entityID },
  };

  for (let i = 0; i < routes.length; i++) {
    if (("visible" in routes[i] && !routes[i].visible) || !routes[i].enabled) continue;

    const route = routes[i];
    for (let j = 0; j < route.sections.length; j++) {
      const section = route.sections[j];
      for (let k = 0; k < section.routes.length; k++) {
        if (!section.routes[k].enabled) continue;

        returnRoute.route = section.routes[k].sref;
        return returnRoute;
      }
    }

    returnRoute.route = route.id;
    return returnRoute;
  }

  returnRoute.params.noAccess = true;
  return returnRoute;
};
