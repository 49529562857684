import { IModalProps, Modal } from "../../components/Modal";
import { IWorkspace } from "../../types/IWorkspace";
import { Formik } from "formik";
import { incrementName } from "../../utils/stringUtils";
import { addTestId } from "../../utils/testIds";
import { withErrorBoundary } from "../../components/ErrorBoundary/ErrorBoundary";

interface WorkspaceModalProps extends Pick<IModalProps, "isOpen" | "onClose"> {
  isOpen: boolean;
  onClose: () => void;
  onSave: (workspace: IWorkspace) => Promise<void>;
  workspace: IWorkspace;
}

export const WorkspaceModal = withErrorBoundary(({ isOpen, onClose, onSave, workspace }: WorkspaceModalProps) => {
  if (!isOpen) return <></>;

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="save-new-workspace">
      <Formik
        initialValues={{ name: incrementName(workspace.name) }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const newWorkspace = { ...workspace, name: values.name };
          await onSave(newWorkspace);
          setSubmitting(false);
          onClose();
        }}
      >
        {({ values, isSubmitting, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <h4>Workspace Name:</h4>
              <input type="text" value={values.name} name="name" onChange={handleChange} />
            </div>
            <div className="modal-footer">
              <div className="controls">
                <button
                  className="btn light-blue"
                  type="submit"
                  disabled={isSubmitting}
                  {...addTestId("saveNewWorkspace")}
                >
                  Save
                </button>
                <button className="btn plain" type="button" onClick={onClose}>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
});
