import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../components/Modal";
import { EditMapForm, IEditMapFormProps } from "../EditMapForm";

type Callback = (r: Promise<void>) => void;

export interface EditMapFormModalHandler {
  open: (props: IEditMapFormProps) => Promise<void>;
}

export const EditMapFormModal = forwardRef<EditMapFormModalHandler>(function EditMapFormModal(_props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const resolver = useRef<Callback>();
  const rejecter = useRef<Callback>();
  const [props, setProps] = useState<IEditMapFormProps>();

  function handleProceed() {
    if (resolver.current) {
      resolver.current(Promise.resolve());
    }
    setIsOpen(false);
    setProps(undefined);
  }

  function handleCancel() {
    if (rejecter.current) {
      rejecter.current(Promise.reject());
    }
    setIsOpen(false);
    setProps(undefined);
  }

  async function open(props: IEditMapFormProps): Promise<void> {
    setIsOpen(true);
    setProps(props);
    return new Promise<void>((resolve, reject) => {
      resolver.current = resolve;
      rejecter.current = reject;
    });
  }

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClose={() => {}} className="col-md-12" id="truterritory-save-map-modal">
      {props && (
        <EditMapForm
          {...props}
          onCancel={handleCancel}
          onSave={(m) => {
            handleProceed();
            // eslint-disable-next-line react/prop-types
            return props.onSave(m);
          }}
        />
      )}
    </Modal>
  );
});
