import { IEntity } from "../types/IEntity";
import { useAppSelector } from "../hooks/useAppSelector";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { computeRoutes, getFirstRoute } from "../hooks/useMenu";

export const CompanySelector = () => {
  const { entityID, session } = useAppSelector((state) => state.app);
  const { entityList = [] } = session || {};
  const { pathname } = useLocation();
  const { entityID: entityIDParam } = useParams();

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // Do not navigate if in admin
    if (pathname.startsWith("/admin")) return;

    // get the selected entityID
    const { value } = e.target;

    // if this is the home page, navigate to the first route for the selected entity
    if (pathname === "/" || pathname === "") {
      const state = getFirstRoute(computeRoutes(parseInt(value), session), parseInt(value));
      navigate(`${state.route}/${value}`);

      // else update the entityID in the path
    } else {
      const newPath = pathname
        .split("/")
        .map((v) => {
          // if the current segment is the entityID, update it
          if (v === entityIDParam) return value;
          return v;
        })
        .filter((v) => v !== "");

      // navigate to the new path
      navigate(newPath.join("/"));
    }
  };

  return (
    <>
      <div id="company" className={["blue", entityList && entityList.length > 1 ? "select-holder" : ""].join(" ")}>
        {entityList ? (
          entityList.length === 1 ? (
            <span className="company-name">{entityList[0].name}</span>
          ) : (
            <>
              <span className="company-name">
                {entityID && entityList ? entityList?.find((e: IEntity) => e.ID === entityID)?.name : "Select..."}
              </span>
              <select name="entityID" id="company-select" onChange={handleChange} value={entityID}>
                <option value="" disabled>
                  Select...
                </option>
                {entityList?.map((entity: IEntity) => (
                  <option key={entity.ID} value={entity.ID}>
                    {entity.name}
                  </option>
                ))}
              </select>
            </>
          )
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
