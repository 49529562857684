import { Key, useMemo } from "react";

import { useAppSelector } from "../hooks/useAppSelector";
import { useMenu } from "../hooks/useMenu";
import { Link } from "react-router-dom";

export const AdminMenu = () => {
  const { routes } = useMenu();

  const menu = useMemo(() => routes.find((i) => i.id === "admin"), [routes]);

  const { activeSection } = useAppSelector((state) => state.app);

  return (
    <div id="side-nav-right" className="nav nav-pills nav-stacked col-xs-2">
      {menu?.sections.map((section, index: Key) => (
        <div key={index}>
          <h3>{section.text}</h3>
          <ul>
            {section.routes.map((item, index: Key) => (
              <li key={index} className={activeSection === item.sref ? "active" : ""}>
                <Link to={item.sref} onClick={() => console.log(item.sref)}>
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};
