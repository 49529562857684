import config from "../configuration";

export const baseConfig = {
  chunking: {
    enabled: true,
    mandatory: true,
    concurrent: {
      enabled: true,
    },
  },
  resume: {
    enabled: true,
  },
  cors: {
    expected: true,
    sendCredentials: true,
  },
  request: {
    endpoint: config.S3_BUCKET_URL,
    accessKey: config.S3_ACCESS_KEY,
  },
};
