/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  useGetUserEntitiesQuery,
  useGetUserQuery,
  useLazyGetUserEntityPermissionsQuery,
  useUpdateUserPermissionsMutation,
} from "../../slices/apiSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { PermissionManager } from "../../services/permission-manager";
import { updateGlobalState } from "../../slices/appSlice";
import { IEntity } from "../../types/IEntity";

export const EditUserPermissions: React.FC = () => {
  // 1) Get the userID from the route params
  const { userID } = useParams<{ userID: string }>();
  const parsedUserId = userID ? parseInt(userID, 10) : 0;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getPermission] = useLazyGetUserEntityPermissionsQuery();
  const [updateUserPermissions] = useUpdateUserPermissionsMutation();

  // 2) Fetch the user data & user-entity list from RTK Query
  const { data: userData, isLoading: isUserLoading, isError: isUserError } = useGetUserQuery(parsedUserId);

  const {
    data: userEntitiesData,
    isLoading: isEntitiesLoading,
    isError: isEntitiesError,
  } = useGetUserEntitiesQuery(parsedUserId);

  // Local states (similar to the old $scope.*)
  const [permissionList, setPermissionList] = useState<any[]>([]);
  const [current, setCurrent] = useState<string[]>([]);
  const [currentPermission, setCurrentPermission] = useState<any>({});
  const [entity, setEntity] = useState<IEntity>();

  // PermissionManager instance, recreated whenever userData?.permissions changes
  const [permissionManager, setPermissionManager] = useState<PermissionManager>();

  useEffect(() => {
    if (userData?.permissions) {
      setPermissionManager(new PermissionManager(userData.permissions));
    }
  }, [userData?.permissions]);

  // Dispatch your global state update once we have userData
  useEffect(() => {
    if (!userData) return;
    dispatch(
      updateGlobalState({
        title: `Manage Permissions for '${userData?.fname} ${userData?.lname}'`,
        h1: "Administration",
        activeSection: "admin.users-and-entities",
      })
    );
  }, [userData, dispatch]);

  // Once userEntitiesData is available, store it as your initial permissionList
  useEffect(() => {
    if (userEntitiesData) {
      // Angular code started with $scope.entities = userEntities, and $scope.permissionList = [entities]
      setPermissionList([userEntitiesData]);
    }
  }, [userEntitiesData]);

  // Replicate $scope.$watch("current")
  useEffect(() => {
    if (!permissionManager || !current?.length) return;
    const permissionString = current.join(".");

    if (current[1] === "lists" && current.length > 2) {
      // The old Angular code checks if the selected permission is an unassigned list, etc.
      // You can add that logic if needed:
      // ...
    } else {
      // Otherwise, get the permission from manager
      const perm = permissionManager.get(permissionString);
      setCurrentPermission(perm || {});
    }
  }, [current, permissionManager, permissionList]);

  /**
   * Triggered when a permissions checkbox is changed
   */
  const changedPermission = (type: string, value: number) => {
    // currentPermission is immutable, so copy to a new object so it can be mutated
    //  not ideal, but in the interests of not changing the original code too much, it's fine
    let updatedCurrentPermission = { ...currentPermission, [type]: value };

    // If there's no permission string, don't do anything
    if (!current?.length || !permissionManager) return;

    // Get the type hierarchy and the highest type
    const typeHierarchy = permissionManager.getTypeHierarchy();
    const highestType = typeHierarchy[permissionManager.findHighestType(updatedCurrentPermission)];

    // If there *is* a highest type
    if (highestType) {
      // Expand it
      const expanded = permissionManager.expandAccessType(highestType);

      // If there isn't a permission at the current level (the level that was clicked), remove all the permissions
      if (!expanded?.[type]) {
        for (const t in expanded) {
          updatedCurrentPermission[t] = 0;
        }

        // Otherwise, expand the type that was clicked and set it on scope
      } else {
        updatedCurrentPermission = permissionManager.expandAccessType(type);
      }

      // Otherwise there's not a highest type,
      // So remove all permissions
    } else {
      for (const t in updatedCurrentPermission) {
        updatedCurrentPermission[t] = 0;
      }
    }

    permissionManager.set(current.join("."), updatedCurrentPermission);
    updateUserPermissions({
      userID: parsedUserId,
      permissions: permissionManager.permissions as { [key: string]: number },
    });
    setCurrentPermission({ ...updatedCurrentPermission });
  };

  /**
   * setEntity => replicate Angular logic
   */
  const handleSetEntity = (ent: IEntity) => {
    setEntity(ent);
    if (ent?.ID !== undefined) {
      ent.permission = `entity.${ent.ID}`;
    }

    const updatedCurrent = [...current];
    updatedCurrent.splice(1, current.length);
    setCurrent(updatedCurrent);

    // In Angular, we do: permissionList[1] = response from getEntityPermissionList
    // For now, just set an empty array or some placeholder
    getPermission({
      entityID: ent?.ID,
      userID: parsedUserId,
    })
      .unwrap()
      .then((response) => {
        // Angular code: permissionList.splice(1, current.length), etc.
        const updatedPermissionList = [...permissionList];
        updatedPermissionList.splice(1, current.length);
        setPermissionList(updatedPermissionList);

        updatedPermissionList[1] = response;
        setPermissionList(updatedPermissionList);
      });

    updatedCurrent[0] = "entity";
    updatedCurrent[1] = `${ent?.ID}`;
    setCurrent(updatedCurrent);
  };

  const openPermission = async (permissionKey: string, permissionObj: any, index: number) => {
    let updatedCurrent = [...current];
    const updatedPermissionList = [...permissionList];

    // 1) If the clicked permission is not the furthest level down,
    //    remove everything down the tree after the clicked permission
    if (index < current.length) {
      updatedPermissionList.splice(index + 1, current.length - index + 1);
      setPermissionList(updatedPermissionList);

      updatedCurrent = updatedCurrent.filter((_, i) => i <= index);
      setCurrent(updatedCurrent);
    }

    // 2) If no children or if it's already in the active tree => just set the selection
    if (!permissionObj?.children || permissionObj?.children === null || updatedCurrent[index] === permissionKey) {
      updatedCurrent[index + 1] = permissionKey;
      setCurrent(updatedCurrent);
      return;
    }

    // 3) Otherwise, set the permissionObj at the next index in our permissionList
    updatedPermissionList[index + 1] = permissionObj;
    setPermissionList(updatedPermissionList);

    // 4) Update the slug in `current`
    updatedCurrent[index + 1] = permissionKey;
    setCurrent(updatedCurrent);

    // 5) Call our placeholder getPermission()
    //    (the same signature as the old Permission.getPermission in Angular)
    try {
      const response = await getPermission({
        entityID: entity?.ID ?? 0,
        userID: parsedUserId,
        path: updatedCurrent[updatedCurrent.length - 1], // the "slugs" beyond the first
      }).unwrap();

      // 6) If this item is still the same (hasn't changed in the meantime),
      //    populate its children from the response
      if (
        updatedPermissionList[index + 1] &&
        updatedPermissionList[index + 1].permission === permissionObj?.permission
      ) {
        updatedPermissionList[index + 1].children = response.children;
        setPermissionList(updatedPermissionList);
      }
    } catch (err) {
      console.error("Failed to getPermission:", err);
    }
  };

  /**
   * checkSelection
   */
  const checkSelection = (permission: any, cur: string[], key?: string) => {
    if (cur.length === 0) return false;
    if (permission?.ID && cur.length === 2 && cur[0] === "entity" && cur[1] == permission.ID) {
      return true;
    }
    return permission === cur?.join(".") || key === cur[cur.length - 1];
  };

  /**
   * cascadePermissions
   */
  const cascadePermissions = () => {
    permissionManager?.clearDescendants(current?.join("."));
    updateUserPermissions({
      userID: parsedUserId,
      permissions: permissionManager?.permissions as { [key: string]: number },
    });
  };

  /**
   * isTypeDisabled
   */
  const isTypeDisabled = (permission: string): boolean => {
    if (!permissionManager || !current?.length) return true;

    const typeIndex = permissionManager.getTypeHierarchy()?.indexOf(permission);
    const highest = permissionManager.findHighestType(currentPermission);
    return typeIndex > highest && highest !== null;
  };

  /**
   * getPermissionClass
   */
  const getPermissionClass = (ogPermissionObj: any, key?: string) => {
    const permissionObj = { ...ogPermissionObj };
    if (!permissionManager) return "";

    if (!permissionObj?.permission && permissionObj?.ID) {
      permissionObj.permission = `entity.${permissionObj.ID}`;
    }

    const classList: string[] = ["permission"];
    const summary = permissionManager.summarize(permissionObj?.permission) || {};
    const highPrefix = "sidebar-high-";
    const lowPrefix = "sidebar-low-";

    if (permissionObj?.children !== null) {
      classList.push("next");
    }
    if (checkSelection(permissionObj, current, key)) {
      classList.push("selected");
    }
    if (summary?.access) {
      classList.push(summary.access);
    }
    if (summary?.high) {
      classList.push(`${highPrefix}${summary.high}`);
    }
    if (summary?.low) {
      classList.push(`${lowPrefix}${summary.low}`);
    }

    return classList.join(" ");
  };

  // We can optionally show loading/error states before rendering
  if (isUserLoading || isEntitiesLoading) {
    return <div>Loading user data...</div>;
  }
  if (isUserError || isEntitiesError || !userData) {
    return <div>Failed to load user or user entities.</div>;
  }

  // Render the final UI
  return (
    <div className="overflow-hidden" id="content">
      <div className="user-permissions">
        <div className="side-bar">
          <button className="btn" onClick={() => navigate("/admin/users-and-entities")}>
            Back
          </button>
          <h3>Permissions at Selected Levels:</h3>
          {/* <pre>{current.join(".")}</pre> */}
          <div className="permissions-list">
            {/* delete */}
            <div className={`permission delete ${isTypeDisabled("delete") ? "disabled" : ""}`}>
              <span>All</span>
              <div className="controls">
                <input
                  id="deletePermission"
                  type="checkbox"
                  checked={currentPermission?.delete === 1}
                  onChange={(e) => changedPermission("delete", e.target.checked ? 1 : 0)}
                  className={
                    (currentPermission?.delete === 1 ? "_checked " : "") + (isTypeDisabled("delete") ? "disabled" : "")
                  }
                />
                <label className="checkbox" htmlFor="deletePermission"></label>
              </div>
            </div>

            {/* create */}
            <div className={`permission create ${isTypeDisabled("create") ? "disabled" : ""}`}>
              <span>Add</span>
              <div className="controls">
                <input
                  id="createPermission"
                  type="checkbox"
                  checked={currentPermission?.create === 1}
                  onChange={(e) => changedPermission("create", e.target.checked ? 1 : 0)}
                  className={
                    (currentPermission?.create === 1 ? "_checked " : "") + (isTypeDisabled("create") ? "disabled" : "")
                  }
                />
                <label className="checkbox" htmlFor="createPermission"></label>
              </div>
            </div>

            {/* update */}
            <div className={`permission update ${isTypeDisabled("update") ? "disabled" : ""}`}>
              <span>Edit</span>
              <div className="controls">
                <input
                  id="updatePermission"
                  type="checkbox"
                  checked={currentPermission?.update === 1}
                  onChange={(e) => changedPermission("update", e.target.checked ? 1 : 0)}
                  className={
                    (currentPermission?.update === 1 ? "_checked " : "") + (isTypeDisabled("update") ? "disabled" : "")
                  }
                />
                <label className="checkbox" htmlFor="updatePermission"></label>
              </div>
            </div>

            {/* collaborate */}
            <div className={`permission collaborate ${isTypeDisabled("collaborate") ? "disabled" : ""}`}>
              <span>Collaborate</span>
              <div className="controls">
                <input
                  id="collaboratePermission"
                  type="checkbox"
                  checked={currentPermission?.collaborate === 1}
                  onChange={(e) => changedPermission("collaborate", e.target.checked ? 1 : 0)}
                  className={
                    (currentPermission?.collaborate === 1 ? "_checked " : "") +
                    (isTypeDisabled("collaborate") ? "disabled" : "")
                  }
                />
                <label className="checkbox" htmlFor="collaboratePermission"></label>
              </div>
            </div>

            {/* read */}
            <div className={`permission read ${isTypeDisabled("read") ? "disabled" : ""}`}>
              <span>View</span>
              <div className="controls">
                <input
                  id="readPermission"
                  type="checkbox"
                  checked={currentPermission?.read === 1}
                  onChange={(e) => changedPermission("read", e.target.checked ? 1 : 0)}
                  className={
                    (currentPermission?.read === 1 ? "_checked " : "") + (isTypeDisabled("read") ? "disabled" : "")
                  }
                />
                <label className="checkbox" htmlFor="readPermission"></label>
              </div>
            </div>

            {/* Cascade */}
            <div className="controls">
              <button className="btn" onClick={cascadePermissions}>
                Cascade
              </button>
            </div>
          </div>
        </div>

        {/* Permission wrapper */}
        <div className="permission-wrapper" data-nicescroll="nicescroll">
          <div className="main">
            <div className="breadcrumbs">
              {/* First breadcrumb => user’s name */}
              <div className="breadcrumb">
                <span className="text">
                  {userData?.fname} {userData?.lname}
                </span>
              </div>
              {current?.slice(1).map((_l, idx) => {
                const nextListItem = permissionList?.[idx + 1] as any;
                const isDisabled = idx + 1 === current.length - 1;

                if (idx === 0 && entity) {
                  return (
                    <div className={`breadcrumb ${isDisabled ? "disabled" : ""}`} key={idx}>
                      <span className="text">{entity?.name}</span>
                    </div>
                  );
                } else if (nextListItem?.name) {
                  return (
                    <div className={`breadcrumb ${isDisabled ? "disabled" : ""}`} key={idx}>
                      <span className="text">{nextListItem?.name}</span>
                    </div>
                  );
                }
              })}
            </div>
            <div style={{ clear: "both" }}></div>

            {/* permissionList columns */}
            {permissionList?.map((permission, columnIndex) => {
              // Same Angular logic => if permission[0].ID => entity column
              let isEntityColumn = false;
              let firstItem: any;
              if (Array.isArray(permission) && permission[0]) {
                firstItem = permission[0];
                isEntityColumn = !!firstItem?.ID;
              }

              return (
                <div className="column" key={columnIndex}>
                  {/* Entities */}
                  {isEntityColumn && Array.isArray(permission) && (
                    <div className="permissions">
                      {permission.map((ent, i) => (
                        <div
                          className={getPermissionClass(ent)}
                          onClick={() => handleSetEntity(ent)}
                          key={ent?.ID ?? i}
                        >
                          <div className="text">{ent?.name}</div>
                          <div className="sidebar"></div>
                        </div>
                      ))}
                    </div>
                  )}

                  {/* Permissions */}
                  {!isEntityColumn && !Array.isArray(permission) && (
                    <div className="permissions">
                      {permission?.children &&
                        Object.entries(permission.children).map(([key, childObj]: [string, any]) => (
                          <div
                            key={key}
                            className={getPermissionClass(childObj, key)}
                            onClick={() => openPermission(key, childObj, columnIndex)}
                          >
                            <div className="text">{childObj?.name}</div>
                            <div className="sidebar"></div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
