/* eslint-disable react/prop-types */
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Modal } from "../../../components/Modal";

type Callback = (r: Promise<void>) => void;

export interface IOpenSaveMapModalProps {
  msg: string;
  saveText: string;
  cancelText: string;
}

export interface OpenSaveMapModalHandler {
  open: (props: IOpenSaveMapModalProps) => Promise<void>;
}

export const OpenSaveMapModal = forwardRef<OpenSaveMapModalHandler>(function OpenSaveMapModal(_props, ref) {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<IOpenSaveMapModalProps>();

  const resolver = useRef<Callback>();
  const rejecter = useRef<Callback>();

  function handleProceed(e?: React.FormEvent) {
    e?.preventDefault();
    if (resolver.current) {
      resolver.current(Promise.resolve());
    }
    setIsOpen(false);
    setProps(undefined);
  }

  function handleCancel() {
    if (rejecter.current) {
      rejecter.current(Promise.reject("User cancelled"));
    }
    setIsOpen(false);
    setProps(undefined);
  }

  async function open(props: IOpenSaveMapModalProps): Promise<void> {
    setIsOpen(true);
    setProps(props);
    return new Promise<void>((resolve, reject) => {
      resolver.current = resolve;
      rejecter.current = reject;
    });
  }

  useImperativeHandle(
    ref,
    () => ({
      open,
    }),
    []
  );

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      {props && (
        <>
          <div className="modal-body">
            <p>There are unsaved changes to the map. {props.msg}</p>
          </div>
          <div className="controls">
            <button className="btn" onClick={handleProceed}>
              {props.saveText}
            </button>
            <button className="btn plain" onClick={handleCancel}>
              {props.cancelText}
            </button>
          </div>
        </>
      )}
    </Modal>
  );
});
