import { ReactNode } from "react";
import { useAppSelector } from "../hooks/useAppSelector";
import { PermissionManager } from "../services/permission-manager";
import { Navigate } from "react-router-dom";

export const RequireSuper = ({ children }: { children: ReactNode }) => {
  const { session } = useAppSelector((state) => state.app);
  const access = new PermissionManager(session?.permissions);

  return access.isSuper() ? <>{children}</> : <Navigate to="/" replace />;
};
