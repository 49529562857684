import { useNavigate, useSearchParams } from "react-router-dom";

import { updateGlobalState } from "../slices/appSlice";
import { Content } from "../design/layout/Content";
import { useEffect, useState } from "react";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { DOCS_ROOT } from "../configuration";
import { computeRoutes, getFirstRoute, useMenu } from "../hooks/useMenu";

export const Home = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { first } = useMenu();
  const [searchParams] = useSearchParams();
  const [accessWarning, setAccessWarning] = useState("");

  useEffect(() => {
    dispatch(
      updateGlobalState({
        title: "Home",
        h1: "Home",
        activeSection: "home",
        activePage: "home",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { session, entityID } = useAppSelector((state) => state.app);

  // If the `docs` param is set in the query string, authorize
  // the user and send them to the docs site at the given path
  useEffect(() => {
    if (searchParams.get("docs") && session?.docs_jwt) {
      const docsParam = searchParams.get("docs") ?? "";
      fetch(`${DOCS_ROOT}/authorize`, {
        credentials: "include",
        headers: { authorization: "Bearer " + session.docs_jwt },
      }).then(() => {
        const path = (docsParam.startsWith("/") ? "" : "/") + docsParam;
        window.location.href = `${DOCS_ROOT}${path}`;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  function setEntity(id: number) {
    const state = getFirstRoute(computeRoutes(id, session), id);

    // if there's no access to anything for single entity users, make sure the no access message appears
    if (state.params.noAccess && session?.entityList.length === 1) {
      setAccessWarning("You have no permissions granted.");
    } else {
      navigate(`${state.route}/${id}`);
    }
  }

  // this gets triggered when the menu is recomputed
  useEffect(() => {
    if (session?.entityList.length === 1) {
      if (first && first.params.noAccess) {
        setAccessWarning("You have no permissions granted.");
      } else {
        navigate(`${first.route}/${entityID}`);
      }
    }

    // the only thing that should trigger this is the menu being recomputed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [first]);

  useEffect(() => {
    if (session?.entityList.length === 0) {
      setAccessWarning("You have no entities assigned.");
    } else if (session?.entityList.length === 1 && !entityID) {
      console.info("setting entity to", session?.entityList[0].ID);
      setEntity(session?.entityList[0].ID);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.entityList]);

  // if there is only one entity, don't show the list, since the above useEffect will take care of the redirect
  if (session?.entityList.length === 1 || accessWarning) {
    return (
      <Content fullWidth>
        {accessWarning ? <h3>{accessWarning} Please contact your administrator or account manager.</h3> : <></>}
      </Content>
    );
  }

  return (
    <Content fullWidth>
      <div className="company-list">
        {session?.entityList?.map((entity) => (
          <div className="company-row ng-scope inactive single all-inactive" key={entity.ID}>
            <div className="triangle" />
            <h3 className="company" onClick={() => setEntity(entity.ID)}>
              <span>{entity.name}</span>
            </h3>
          </div>
        ))}
      </div>
    </Content>
  );
};
