export const Collapse = () => (
  <svg
    version="1.1"
    id="Icons"
    x="0px"
    y="0px"
    viewBox="0 0 2048 2048"
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
  >
    <defs id="defs4" />

    <g id="expand">
      <polygon
        points="641.7,0 0,0 0,641.7 81.9,641.7 81.9,143.4 689.5,750.9 750.9,689.5 143.4,81.9 641.7,81.9 "
        id="polygon1"
        transform="rotate(180,375.45,375.45)"
      />
      <polygon
        points="1406.3,81.9 1904.6,81.9 1297.1,689.5 1358.5,750.9 1966.1,143.4 1966.1,641.7 2048,641.7 2048,0 1406.3,0 "
        id="polygon2"
        transform="rotate(180,1672.55,375.45)"
      />
      <polygon
        points="81.9,1904.6 81.9,1406.3 0,1406.3 0,2048 641.7,2048 641.7,1966.1 143.4,1966.1 750.9,1358.5 689.5,1297.1 "
        id="polygon3"
        transform="rotate(180,375.45,1672.55)"
      />
      <polygon
        points="1358.5,1297.1 1297.1,1358.5 1904.6,1966.1 1406.3,1966.1 1406.3,2048 2048,2048 2048,1406.3 1966.1,1406.3 1966.1,1904.6 "
        id="polygon4"
        transform="rotate(180,1672.55,1672.55)"
      />
    </g>
  </svg>
);
