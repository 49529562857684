import { useState, useMemo } from "react";
import {
  useGetWorkspacesWithMapsQuery,
  useUpdateMapMutation,
  useUpdateUserPreferencesMutation,
} from "../../slices/apiSlice";
import { updateWorkspaceFolding, updateMapFolding } from "../../slices/appSlice";

import { IWorkspace } from "../../types/IWorkspace";
import { IMap } from "../../types/IMap";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { classNameMapper } from "../../utils/classNameMapper";
import { ConfirmButton } from "../../components/ConfirmButton";
import { SortableUl } from "../../components/Sortable/SortableUl";
import { SortableLi } from "../../components/Sortable/SortableLi";
import { arrayMove, useSortable } from "@dnd-kit/sortable";
import { DragEndEvent, DragStartEvent } from "@dnd-kit/core";
import { useHasMapAccess } from "./hasMapAccess";

interface TruTerritoryBookmarksProps {
  loadMap: (map: IMap) => void;
  deleteMap: (map: IMap) => void;
  currentMap: IMap;
}

interface MapsProps {
  maps: IMap[];
  loadMap: (map: IMap) => void;
  deleteMap: (map: IMap) => void;
  currentMap: IMap;
  className: string;
}

type FoldingState = { [key: number]: boolean };
type DraggingState = { [key: string]: boolean };

function collapsibleClass(folding: FoldingState, id: number): string {
  return !(id in folding) || !folding[id] ? "opened" : "closed";
}

/**
 * Internal component for clicking and dragging a bookmark.
 **/
const DragHandle = ({ id }: { id: number }) => {
  const { listeners, attributes } = useSortable({ id });
  return <div className="handle" title="Drag here to reorder" {...listeners} {...attributes} />;
};

/**
 * Internal component for rendering a list of Maps. Recursively displays children.
 **/
const Maps: React.FC<MapsProps & JSX.IntrinsicAttributes> = (props: MapsProps) => {
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const [updateMap] = useUpdateMapMutation();
  const dispatch = useAppDispatch();

  const [draggingState, setDraggingState] = useState<DraggingState>({});

  const { ID: userID, preferences } = useAppSelector((state) => state.app.session) ?? {};
  const hasAccess = useHasMapAccess();
  const mapFolding = preferences?.mapFolding || {};

  /**
   * Fold/Unfold a map.
   */
  function toggleMapFolding(mapID: number) {
    const folding = { ...mapFolding, [mapID]: !mapFolding[mapID] };
    dispatch(updateMapFolding(folding));
    updateUserPreferences({ ID: userID!, preferences: { ...preferences, mapFolding: folding } });
  }

  /**
   * Save the new position of a dragged/reorded map bookmark.
   */
  function mapDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    // Turn off the dragging state (for CSS classes).
    const dragging = { ...draggingState };
    dragging[active.id] = false;
    setDraggingState(dragging);

    // Reorder array based on dragging, then persist the new order map-by-map.
    const oldIndex = props.maps.findIndex((w) => w.ID == active.id);
    const newIndex = props.maps.findIndex((w) => w.ID == over?.id);
    if (oldIndex == newIndex) return;

    const newArray = arrayMove(props.maps, oldIndex > 0 ? oldIndex : 0, newIndex > 0 ? newIndex : 0);
    newArray.forEach((m, i) => updateMap({ ID: m.ID, workspaceID: m.workspaceID, sorder: i })); // Including workspaceID so that RTK Query can update the workspace cache. (Leaky...)
  }

  /**
   * Start dragging a map bookmark.
   */
  function mapDragStart(event: DragStartEvent) {
    // Turn on the dragging state (for CSS classes).
    const dragging = { ...draggingState };
    dragging[event.active.id] = true;
    setDraggingState(dragging);
  }

  return (
    <>
      <SortableUl
        items={props.maps.map((m) => m.ID) ?? []}
        onDragEnd={mapDragEnd}
        onDragStart={mapDragStart}
        id="bookmarks-sortable2"
        className={props.className}
      >
        {props.maps.map((map) => (
          <SortableLi
            key={map.ID}
            className={draggingState[map.ID] ? "dragging" : ""}
            id={map.ID}
            useSeparateDragHandle
          >
            {!!map.children?.length && (
              <div
                className={classNameMapper({ triangle: true, open: !mapFolding[map.ID] })}
                onClick={toggleMapFolding.bind(null, map.ID)}
                title="Click to show more"
              ></div>
            )}
            <a
              onClick={props.loadMap.bind(null, map)}
              className={classNameMapper({ active: props.currentMap?.ID === map.ID })}
            >
              {hasAccess("update") && <DragHandle id={map.ID} />}
              <span title={`Click to load "${map.name}"`}>
                {hasAccess("update") && !map.children?.length && (
                  <span className="controls">
                    <ConfirmButton
                      type="del"
                      text={`Are you sure you want to delete '${map.name}'?`}
                      yes={"Delete"}
                      callback={props.deleteMap.bind(null, map)}
                    />
                  </span>
                )}
                {map.name}
              </span>
            </a>
            {!!map.children?.length && (
              <div className={["collapsible", collapsibleClass(mapFolding, map.ID)].join(" ")}>
                <Maps
                  maps={map.children}
                  loadMap={props.loadMap}
                  deleteMap={props.deleteMap}
                  currentMap={props.currentMap}
                  className="children"
                />
              </div>
            )}
          </SortableLi>
        ))}
      </SortableUl>
    </>
  );
};

/**
 * Exported component for rendering bookmarks from the list of workspaces.
 */
export const TruTerritoryBookmarks: React.FC<TruTerritoryBookmarksProps & JSX.IntrinsicAttributes> = (
  props: TruTerritoryBookmarksProps
) => {
  const [updateUserPreferences] = useUpdateUserPreferencesMutation();
  const dispatch = useAppDispatch();
  const { ID: userID, preferences } = useAppSelector((state) => state.app.session) ?? {};
  const workspaceFolding = preferences?.workspaceFolding || {};

  // Fetch the workspaces and maps and organize them into nested, sorted bookmarks.
  const { data: rawWorkspaces } = useGetWorkspacesWithMapsQuery();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const workspaces = useMemo(() => organize(rawWorkspaces || []), [rawWorkspaces]);

  /**
   * From a list of workspaces, filter to ones containing maps, then sort and group.
   */
  function organize(workspaces: IWorkspace[]): IWorkspace[] {
    return workspaces
      .filter((w) => w.maps?.length)
      .sort((a, b) => a.sorder - b.sorder)
      .map((w) => ({ ...w, maps: groupMaps(w.maps) }));
  }

  /**
   * Given a flat list of maps, create hierarchy of parents -> children.
   */
  function groupMaps(maps: IMap[]): IMap[] {
    const children = maps
      .filter((m) => !!m.parentID)
      .sort((a, b) => a.sorder - b.sorder)
      .reduce(_accumulateChildren, {});

    const parents = maps
      .filter((m) => !m.parentID)
      .sort((a, b) => a.sorder - b.sorder)
      .map((m): IMap => ({ ...m, children: children[m.ID] || [] }));

    // Make an object of mapID -> children.
    function _accumulateChildren(accum: { [key: number]: IMap[] }, map: IMap) {
      accum = { ...accum };

      if (!map.parentID) {
        return accum;
      }

      if (!(map.parentID in accum)) {
        accum[map.parentID] = [map];
      } else {
        accum[map.parentID] = [...accum[map.parentID], map];
      }

      return accum;
    }

    return parents;
  }

  /**
   * Fold/Unfold the workspace.
   */
  function toggleWorkspaceFolding(workspaceID: number) {
    const folding = { ...workspaceFolding };
    folding[workspaceID] = !folding[workspaceID];
    dispatch(updateWorkspaceFolding(folding));
    updateUserPreferences({ ID: userID!, preferences: { ...preferences, workspaceFolding: folding } });
  }

  return (
    <div className="truterritory-bookmarks">
      <div className="bookmarks-list">
        <ul>
          {workspaces.map((workspace) => (
            <li
              className={["workspace", collapsibleClass(workspaceFolding, workspace.ID)].join(" ")}
              key={workspace.ID}
            >
              <div className="bookmark-heading" onClick={toggleWorkspaceFolding.bind(null, workspace.ID)}>
                <h3>
                  {workspace.name}
                  <span className="closed-count">[{workspace.maps.length}]</span>
                </h3>
                <span className="closed-indicator"> &#9660;</span>
              </div>

              <div className="collapsible">
                <Maps
                  maps={workspace.maps}
                  loadMap={props.loadMap}
                  deleteMap={props.deleteMap}
                  currentMap={props.currentMap}
                  className="bookmarks"
                ></Maps>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
