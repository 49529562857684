import { DataCollections } from "./DataCollections";
import { DataNavbar } from "./DataNavbar";
import { InfiniteConsole } from "./InfiniteConsole";

export const DataWrapper = () => (
  <div id="page-wrap">
    <div id="content-wrap">
      <DataNavbar />
      <div id="content" className="has-navbar no-secondary-menu row">
        <div id="data">
          <DataCollections />

          <InfiniteConsole />
        </div>
      </div>
    </div>
  </div>
);
