import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  UniqueIdentifier,
  useDraggable,
  useDroppable,
} from "@dnd-kit/core";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../hooks/useAppDispatch";
import { updateGlobalState } from "../../slices/appSlice";
import {
  baseApi,
  useDeleteUserMutation,
  useGetEntitiesQuery,
  useGetUsersQuery,
  useUpdateEntityUsersMutation,
} from "../../slices/apiSlice";
import { ConfirmButton } from "../../components/ConfirmButton";
import { IUser } from "../../types/IUser";
import { Button } from "../../design/Button";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { IEntity } from "../../types/IEntity";
import { classNameMapper } from "../../utils/classNameMapper";
import { Paging } from "../../components/Paging";
import { AnimatedRightPanelOutlet } from "../../components/AnimatedOutlet/AnimatedOutlet";

export const UsersAndEntities = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [updateEntity] = useUpdateEntityUsersMutation();
  const [deleteUser] = useDeleteUserMutation();

  useEffect(() => {
    dispatch(
      updateGlobalState({
        title: "User and Entity Manager",
        h1: "Administration",
        activePage: "admin",
        activeSection: "/admin/users-and-entities",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [userPage, setUserPage] = useState(1);
  const [entityPage, setEntityPage] = useState(1);

  const [searchValues, setSearchValues] = useState<{
    userSearchQuery: string;
    userSearchType: "name" | "email";
    entitySearchQuery: string;
    entitySearchType: "name" | "username";
  }>({
    userSearchQuery: "",
    userSearchType: "name",
    entitySearchQuery: "",
    entitySearchType: "name",
  });

  const {
    data: { data: users, meta: usersMeta } = { data: [] },
    isLoading: isLoadingUsers,
    error: usersError,
  } = useGetUsersQuery({
    ...{ [searchValues.userSearchType]: searchValues.userSearchQuery },
    page: userPage,
  });

  const {
    data: { data: entities, meta: entitiesMeta } = { data: [] },
    isLoading: isLoadingEntities,
    error: entitiesError,
  } = useGetEntitiesQuery({
    ...{ [searchValues.entitySearchType]: searchValues.entitySearchQuery },
    page: entityPage,
  });

  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null); // Track the currently dragged item's ID
  const [activeType, setActiveType] = useState<string | null>(null); // Track the currently dragged item's type
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null); // Track the current drop target's ID

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id); // Set the active ID when dragging starts
    setActiveType(event.active.data.current?.type);
  };

  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setActiveId(null);
    setOverId(null);
    setActiveType(null);

    if (!over) return;
    const { id: activeId } = active;
    const { id: overId } = over;
    if (overId === "users-list") {
      const [entityID, userID] = `${activeId}`.split(" ").map((id) => parseInt(id));
      const entity = entities?.find((entity) => entity.ID === entityID);
      if (entity) {
        updateEntity({
          entityID,
          users: entity?.users.filter((user) => user.ID !== userID).map((user) => user.ID),
        });
        // Optimistically update the local state
        // TODO: update when search is active
        dispatch(
          baseApi.util.updateQueryData("getEntities", undefined, (draft) => {
            const entity = draft.data.find((entity) => entity.ID === entityID);
            if (entity) {
              entity.users = entity.users.filter((user) => user.ID !== userID);
            }
          })
        );
      }
    } else if (overId) {
      const entityID = parseInt(`${overId}`);
      const userID = parseInt(`${activeId}`);
      const entity = entities?.find((entity) => entity.ID === entityID);
      const user = users?.find((user) => user.ID === userID);

      if (user && entity && !entity.users.find((user) => user.ID === userID)) {
        updateEntity({
          entityID,
          users: [...entity?.users.map((user) => user.ID), userID],
        });
        // Optimistically update the local state
        // TODO: update when search is active
        dispatch(
          baseApi.util.updateQueryData("getEntities", undefined, (draft) => {
            draft.data.find((entity) => entity.ID === entityID)?.users.unshift(user);
          })
        );
      }
    }
  };

  const handleDragOver = ({ over }: DragOverEvent) => {
    setOverId(over?.id || null);
  };

  return (
    <>
      <Navbar onChange={(values) => setSearchValues(values)} />
      <div id="content">
        <div className="row admin-entities">
          <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragOver={handleDragOver}>
            <div className="col-sm-4">
              {!users?.length && searchValues.userSearchQuery.length && !isLoadingUsers && !usersError ? (
                <h4 className="empty">Sorry, your search did not match any users</h4>
              ) : (
                <DroppableArea droppableId={"users-list"} className="user-list sortable-list">
                  {users?.map((user) => (
                    <DraggableItem key={user.ID} id={user.ID.toString()} data={{ type: "user" }}>
                      <span>
                        {!!user.fname || !!user.lname ? `${user.fname} ${user.lname}` : `[no name] ${user.uname}`}
                      </span>
                      <div className="controls">
                        <ConfirmButton
                          type="del"
                          className="btn del"
                          title={`Delete '${user.fname} ${user.lname}'`}
                          text={`Are you sure you want to delete '${user.fname} ${user.lname}'?`}
                          callback={() => {
                            console.log("deleteUser", user.ID);
                            deleteUser(user.ID);
                          }}
                          stopButtonEventPropogation
                        />
                        <Button
                          className="btn settings"
                          title="Edit settings for '{{user.fname}} {{user.lname}}'"
                          onClick={() => navigate(`/admin/users-and-entities/edit-settings/${user.ID}`)}
                          stopEventPropogation
                        />
                        <Button
                          variant="edit"
                          title="Edit '{{user.fname}} {{user.lname}}'"
                          onClick={() => navigate(`/admin/users-and-entities/user/${user.ID}`)}
                          stopEventPropogation
                        />
                      </div>
                    </DraggableItem>
                  ))}
                </DroppableArea>
              )}
              <div className="bottom-bar">
                <Link className="btn add" to="/admin/users-and-entities/user" />
                {usersMeta && (
                  <Paging
                    className="pagination"
                    pages={usersMeta}
                    onChange={(page) => {
                      console.log("setUserPage", page);
                      setUserPage(page);
                    }}
                    verbosity={2}
                  />
                )}
              </div>
            </div>
            <div className="col-sm-4">
              {!entities?.length && searchValues.entitySearchQuery.length && !isLoadingEntities && !entitiesError ? (
                <h4 className="empty">Sorry, your search did not match any entities</h4>
              ) : (
                <ul className="entity-list">
                  {entities?.map((entity) => (
                    <li key={entity.ID}>
                      <h4>{entity.name}</h4>
                      <div className="controls">
                        <Button
                          variant="edit"
                          title="Edit '{{entity.name}}'"
                          onClick={() => {
                            navigate(`/admin/users-and-entities/entity/${entity.ID}`);
                          }}
                          stopEventPropogation
                        />
                        <Button
                          variant="settings"
                          title="Edit the settings for '{{entity.name}}'"
                          onClick={() => navigate(`/admin/users-and-entities/edit-entity-permissions/${entity.ID}`)}
                          stopEventPropogation
                        />
                      </div>
                      <DroppableArea
                        droppableId={entity.ID.toString()}
                        className={classNameMapper(
                          { dropping: overId == entity.ID && activeType === "user" },
                          "entities-user-sublist sortable-list"
                        )}
                      >
                        {overId == entity.ID && activeType === "user" && <li />}
                        {entity.users?.map((user) => (
                          <DraggableItem
                            key={`${entity.ID}-user-${user.ID}`}
                            id={`${entity.ID} ${user.ID}`}
                            data={{ type: "entity-user" }}
                          >
                            {user.fname} {user.lname}
                          </DraggableItem>
                        ))}
                      </DroppableArea>
                    </li>
                  ))}
                </ul>
              )}
              <div className="bottom-bar">
                <Link className="btn add" to="/admin/users-and-entities/entity" />
                {entitiesMeta && (
                  <Paging
                    className="pagination"
                    pages={entitiesMeta}
                    onChange={(page) => setEntityPage(page)}
                    verbosity={2}
                  />
                )}
              </div>
            </div>

            <DragOverlay>
              {activeId && users && entities && (
                <RenderedDragOverlay activeId={activeId} overId={overId} users={users} entities={entities} />
              )}
            </DragOverlay>
          </DndContext>
          <AnimatedRightPanelOutlet />
        </div>
      </div>
    </>
  );
};

const Navbar = ({
  onChange,
}: {
  onChange: (value: {
    userSearchQuery: string;
    userSearchType: "name" | "email";
    entitySearchQuery: string;
    entitySearchType: "name" | "username";
  }) => void;
}) => {
  // user search query
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const debouncedUserSearchQuery = useDebouncedValue(userSearchQuery);
  const [userSearchType, setUserSearchType] = useState<"name" | "email">("name");

  // entity search query
  const [entitySearchQuery, setEntitySearchQuery] = useState("");
  const debouncedEntitySearchQuery = useDebouncedValue(entitySearchQuery);
  const [entitySearchType, setEntitySearchType] = useState<"name" | "username">("name");

  useEffect(() => {
    onChange({
      userSearchQuery: debouncedUserSearchQuery,
      userSearchType,
      entitySearchQuery: debouncedEntitySearchQuery,
      entitySearchType,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedUserSearchQuery, userSearchType, debouncedEntitySearchQuery, entitySearchType]);

  return (
    <nav className="navbar navbar-default" id="top-nav-secondary">
      <ul className="nav navbar-nav">
        <li>
          Users:
          <input
            type="text"
            placeholder="SEARCH"
            value={userSearchQuery}
            name="userSearchQuery"
            onChange={({ target }) => setUserSearchQuery(target.value)}
          />
        </li>
        <li className="search-filter">
          <div className="select-holder">
            <select
              name="userSearchType"
              onChange={({ target }) => setUserSearchType(target.value as "name" | "email")}
            >
              <option value="name">Name</option>
              <option value="email">Email</option>
            </select>
          </div>
        </li>
        <li>
          Entities:
          <input
            type="text"
            placeholder="SEARCH"
            value={entitySearchQuery}
            name="entitySearchQuery"
            onChange={({ target }) => setEntitySearchQuery(target.value)}
          />
        </li>
        <li className="search-filter">
          <div className="select-holder">
            <select
              name="entitySearchType"
              onChange={({ target }) => setEntitySearchType(target.value as "name" | "username")}
            >
              <option value="name">Name</option>
              <option value="username">User Name</option>
            </select>
          </div>
        </li>
      </ul>
    </nav>
  );
};

const DraggableItem = ({ id, children, data }: { id: string; children: React.ReactNode; data?: { type: string } }) => {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id,
    data,
  });

  const style = {
    transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
    zIndex: transform ? 1 : undefined,
  };

  return (
    <li ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </li>
  );
};

const DroppableArea = ({
  droppableId,
  children,
  className,
}: {
  droppableId: string;
  children: React.ReactNode;
  className?: string;
}) => {
  const { setNodeRef } = useDroppable({ id: droppableId });

  return (
    <ul ref={setNodeRef} className={className}>
      {children}
    </ul>
  );
};

const RenderedDragOverlay = ({
  activeId,
  users,
  entities,
}: {
  activeId: UniqueIdentifier;
  overId: UniqueIdentifier | null;
  users: IUser[];
  entities: IEntity[];
}) => {
  const user = useMemo(
    () =>
      users.find((user) => user.ID == activeId) ??
      entities
        .flatMap((entity) => entity.users)
        .find((user) => user.ID === parseInt(activeId.toString().split(" ")[1])),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeId, users]
  );

  return activeId ? (
    <ul className="user-list drag-overlay">
      <li style={{ cursor: "grabbing" }}>
        {user?.fname} {user?.lname}
      </li>
    </ul>
  ) : (
    <></>
  );
};
