import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { useState } from "react";

import { Modal } from "../Modal";
import { Button } from "../../design/Button";

interface ErrorBoundaryModalProps {
  error?: Error;
  onClose?: () => void;
}

export const ErrorBoundaryModal = ({ error, onClose = () => {} }: ErrorBoundaryModalProps) => {
  const [isErrorExpanded, setIsErrorExpanded] = useState(false);

  return (
    <Modal isOpen={Boolean(error)} onClose={onClose}>
      <div className="modal-body">
        <h2>Something went wrong.</h2>
        <p>We encountered an unexpected error.</p>
        <Box sx={{ fontSize: "1rem" }}>
          <Box component="pre" sx={{ mb: 0, cursor: "pointer" }} onClick={() => setIsErrorExpanded(!isErrorExpanded)}>
            <Box component="span" sx={{ fontWeight: "bold", mr: 1 }}>
              {isErrorExpanded ? "▼" : "▶"}
            </Box>
            {error?.message}
          </Box>
          <Collapse in={isErrorExpanded}>
            <Box component="pre" sx={{ mt: 0 }}>
              {error?.stack}
            </Box>
          </Collapse>
        </Box>
      </div>
      <div className="modal-footer">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};
