import { createBrowserRouter, Navigate, Outlet, RouterProvider, useParams } from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { Provider } from "react-redux";

import { Home } from "./pages/Home";
import { store } from "./store";
import { setEntityID, updateGlobalState } from "./slices/appSlice";
import { useAppSelector } from "./hooks/useAppSelector";
import { Modal } from "./components/Modal";
import { Button } from "./design/Button";
import { NotFound } from "./pages/NotFound";
import { BaseHeader, Header } from "./components/Header";
import { LoginModal } from "./components/LoginModal";
import { NewVersionAvailableModal } from "./components/NewVersionAvailable";
import { SessionPoller } from "./components/pollers/SessionPoller";
import { VersionPoller } from "./components/pollers/VersionPoller";
import { TruTerritory } from "./pages/TruTerritory/TruTerritory";
import { Menu } from "./components/Menu";
import { useMenu } from "./hooks/useMenu";
import { DataWrapper } from "./pages/Data/DataWrapper";
import { UsersAndEntities } from "./pages/Admin/UsersAndEntities";
import { AdminMenu } from "./components/AdminMenu";
import { EditUser } from "./pages/Admin/EditUser";
import { EditUserPermissions } from "./pages/Admin/EditUserPermissions";
import { EditEntityPermissions } from "./pages/Admin/EditEntityPermissions";
import { EditEntity } from "./pages/Admin/EditEntity";
import { RequireSuper } from "./components/RequireSuper";
import { MyProfile } from "./pages/MyProfile";
import { ResetPasswordModal } from "./components/ResetPasswordModal";
import { HashRedirect } from "./components/HashRedirect";

export const Router = () => {
  const router = createBrowserRouter([
    {
      path: "",
      element: (
        <HashRedirect>
          <Outlet />
        </HashRedirect>
      ),
      children: [
        {
          path: "reset-password/:token",
          element: (
            <>
              <BaseHeader h1={""} baseRoute={""} />
              <ResetPasswordModal />
            </>
          ),
        },
        {
          path: "",
          errorElement: (
            <>
              <SessionPoller />
              <ErrorHandler />
              <Layout>
                <NotFound />
              </Layout>
            </>
          ),
          element: (
            <>
              <SessionPoller />
              <ErrorHandler />
              <Layout>
                <Outlet />
              </Layout>
            </>
          ),
          children: [
            {
              path: "",
              element: (
                <>
                  <Menu />
                  <Home />
                </>
              ),
            },
            {
              path: "my-profile",
              element: (
                <>
                  <Menu />
                  <MyProfile />
                </>
              ),
            },
            {
              path: "mapping/:entityID",
              element: (
                <EntityIDGuard>
                  <Menu />
                  <TruTerritory />
                </EntityIDGuard>
              ),
            },
            {
              path: "data/:entityID",
              element: (
                <EntityIDGuard>
                  <Menu />
                  <DataWrapper />
                </EntityIDGuard>
              ),
            },
            {
              path: "admin",
              element: (
                <RequireSuper>
                  <Menu />
                  <AdminMenu />
                  <div id="page-wrap">
                    <div id="content-wrap">
                      <Outlet />
                    </div>
                  </div>
                </RequireSuper>
              ),
              children: [
                {
                  path: "",
                  element: <Navigate to="users-and-entities" replace />,
                },
                { path: "users-and-entities/edit-settings/:userID", element: <EditUserPermissions /> },
                {
                  path: "users-and-entities",
                  element: <UsersAndEntities />,
                  children: [
                    { path: "user/:userID", element: <EditUser /> },
                    { path: "user", element: <EditUser /> },
                    { path: "entity/:entityID", element: <EditEntity /> },
                    { path: "entity", element: <EditEntity /> },
                    { path: "edit-entity-permissions/:entityID", element: <EditEntityPermissions /> },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ]);

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  );
};

const Layout = ({ children }: { children: ReactNode }) => {
  const { session } = useAppSelector((state) => state.app);

  const { enabled } = useMenu();

  // if there's only one menu, make sure the proper classes are applied to the page
  useEffect(() => {
    if (enabled.length <= 1) {
      document.getElementById("page")?.classList.add("single-module");
    } else {
      document.getElementById("page")?.classList.remove("single-module");
    }
  }, [enabled]);

  return (
    <>
      {session ? (
        <>
          <VersionPoller />
          <Header />
          {children}
        </>
      ) : (
        <BaseHeader h1={""} baseRoute={""} />
      )}
    </>
  );
};

const ErrorHandler = () => {
  const { error } = useAppSelector((state) => state.app);

  switch (error?.exception) {
    case "LoginRequiredException":
      return <LoginModal />;
    case "newVersionAvailable":
      return <NewVersionAvailableModal />;

    default:
      return (
        <Modal isOpen={!!error} onClose={() => store.dispatch(updateGlobalState({ error: undefined }))}>
          <div className="modal-body">
            <p>{error?.code}</p>
            <p>{error?.message}</p>
          </div>
          <div className="modal-footer">
            <Button onClick={() => store.dispatch(updateGlobalState({ error: undefined }))}>Close</Button>
          </div>
        </Modal>
      );
  }

  return error ? <div>Something went wrong: {JSON.stringify(error)}</div> : "";
};

const EntityIDGuard = ({ children }: { children: ReactNode }) => {
  const { entityID: storeEntityID } = useAppSelector((state) => state.app);
  const { entityID: paramsEntityID } = useParams<{ entityID: string }>();

  useEffect(() => {
    if (paramsEntityID && storeEntityID !== parseInt(paramsEntityID)) {
      store.dispatch(setEntityID(paramsEntityID));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsEntityID]);

  return storeEntityID ? <>{children}</> : <></>;
};
