import { createRoot } from "react-dom/client";

import { bootstrap } from "./services/datadog";
import { Router } from "./Router";

import "./configuration/setup";

bootstrap();

const domNode = document.getElementById("page");
if (domNode) {
  const root = createRoot(domNode);
  root.render(<Router />);
}
