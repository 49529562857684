import { AnimatePresence, motion } from "framer-motion";
import { useOutlet } from "react-router-dom";

const variants = {
  hidden: { opacity: 1, x: "100%", y: 0 },
  enter: { opacity: 1, x: 0, y: 0 },
  exit: { opacity: 1, x: "100%", y: 0 },
};
const transition = { duration: 0.35, type: "linear" };

export const AnimatedRightPanelOutlet = () => {
  const element = useOutlet();

  return (
    <AnimatePresence mode="sync" initial={false}>
      {element && (
        <motion.div
          initial="hidden"
          animate="enter"
          exit="exit"
          variants={variants}
          transition={transition}
          style={{ height: "100%", position: "relative" }}
          className="right-wrap right"
        >
          {element}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
