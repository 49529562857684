import { useNavigate, useParams } from "react-router-dom";
import { useGetEntityQuery, useGetPermissionsQuery, useUpdateEntityPermissionsMutation } from "../../slices/apiSlice";

export const EditEntityPermissions = () => {
  const navigate = useNavigate();
  const { entityID } = useParams<{ entityID: string }>();
  const [updatePermissions] = useUpdateEntityPermissionsMutation();

  const { data: permissions } = useGetPermissionsQuery();
  const { data: entity } = useGetEntityQuery(parseInt(entityID ?? ""));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const permission = event.target.name;
    const value = event.target.checked;

    updatePermissions({
      entityID: parseInt(entityID ?? ""),
      permissions: {
        ...entity?.permissions,
        [permission]: value ? 1 : 0,
      },
    });
  };

  return (
    <>
      <div className="manage-header">
        <h4>Manage Permissions</h4>
      </div>
      <div className="manage-body">
        <table className="table permissions">
          <tbody>
            {permissions?.map((permission) => (
              <tr key={`permission-${permission.permission}`}>
                <td>
                  <span>{permission.name}</span>
                </td>
                <td>
                  <Switch
                    id={`permission-${permission.name}-${entityID}`}
                    name={permission.permission}
                    checked={!!entity?.permissions[permission.permission]}
                    onChange={handleChange}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="controls">
        <button className="btn" onClick={() => navigate("/admin/users-and-entities")}>
          Close
        </button>
      </div>
    </>
  );
};

const Switch = ({
  checked,
  onChange,
  id,
  name,
}: {
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  name?: string;
}) => (
  <>
    <div className="checkbox-switch">
      <input type="checkbox" name={name} id={id} onChange={onChange} checked={checked} />
      <label htmlFor={id}>
        <div className="inner">
          <div className="inner-on">ACTIVE</div>
          <div className="inner-off">DISABLED</div>
        </div>
        <div className="switch"></div>
      </label>
    </div>
  </>
);
