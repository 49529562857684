import { ReactNode, useCallback, useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  keyboard?: boolean;
  id?: string;
  className?: string;

  // sometimes control flow isn't passed back correctly
  //  if the modal is still waiting for an animation to finish
  //  for instance, a retry modal (password modal on public)
  skipExitAnimation?: boolean;
}

export const Modal = ({
  isOpen: externalIsOpen,
  onClose,
  keyboard = true,
  children,
  id,
  className,
  skipExitAnimation,
}: IModalProps) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (externalIsOpen) {
      setTimeout(() => setShouldAnimate(true), 0);

      setIsOpen(true);
    } else {
      setShouldAnimate(false);
      if (modalRef && modalRef.current && !skipExitAnimation) {
        modalRef.current.addEventListener("transitionend", () => setIsOpen(false));
      } else {
        setIsOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalIsOpen]);

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    if (keyboard) {
      document.addEventListener("keydown", escFunction, false);
    }
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escFunction]);

  return (
    <>
      {isOpen &&
        createPortal(
          <div onClick={(e) => e.stopPropagation()}>
            {" "}
            {/* Why stopPropagation()? Read and be horrified: https://github.com/facebook/react/issues/11387 */}
            {isOpen && <div className={`fade modal-backdrop ${shouldAnimate ? "in" : ""}`}></div>}
            <div
              className={`modal fade ${shouldAnimate ? "in" : ""} ${className ?? ""}`}
              style={isOpen ? { display: "block" } : {}}
              ref={modalRef}
            >
              {isOpen && (
                <div className="modal-dialog" id={id}>
                  <div className="modal-content">{children}</div>
                </div>
              )}
            </div>
          </div>,
          document.getElementById("page")!
        )}
    </>
  );
};
