/**
 * Confirm button with the same api as epiConfirm
 */

import { useMemo } from "react";
import { ReactNode, useState } from "react";
import { Button } from "../design/Button";
import { addTestId } from "../utils/testIds";
import { Modal } from "./Modal";

interface IConfirmButtonProps {
  text: string;
  yes?: string;
  no?: string;
  callback: () => void;
  idiotTest?: string;
  details?: string;
  children?: ReactNode;
  type?: "del";
  title?: string;
  className?: string;
  stopButtonEventPropogation?: boolean;
}

export const confirmModalTestIds = {
  input: "ConfirmModalInput",
  yesButton: "ConfirmModalYesButton",
};

export const ConfirmButton = ({
  type,
  text,
  details,
  callback,
  idiotTest,
  yes = "Proceed",
  no = "Cancel",
  title,
  className,
  stopButtonEventPropogation,
}: IConfirmButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputVal, setInputVal] = useState("");

  const canProceed = useMemo(() => !idiotTest || inputVal === idiotTest, [inputVal, idiotTest]);

  function toggleOpen(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation();
    setIsOpen((v) => !v);
  }

  return (
    <>
      {type === "del" ? (
        <Button
          className={`del${className ? " " + className : ""}`}
          onClick={toggleOpen}
          title={title}
          stopEventPropogation={stopButtonEventPropogation}
        />
      ) : (
        <Button
          className={className ?? ""}
          onClick={toggleOpen}
          title={title}
          stopEventPropogation={stopButtonEventPropogation}
        >
          {text}
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} id="confirmModal">
        <div style={{ textAlign: "left" }}>
          <div className="modal-header">
            <h3>{text}</h3>
          </div>
          {(details || idiotTest) && (
            <div className="modal-body">
              {details && <p>{details}</p>}
              {idiotTest && (
                <>
                  <p className="idiot-test">Please type &apos;{idiotTest}&apos; to in order to proceed</p>
                  <form
                    name="confirmForm"
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (canProceed) callback();
                    }}
                  >
                    <input
                      type="text"
                      autoFocus
                      className={!canProceed ? "error" : ""}
                      onChange={({ target }) => setInputVal(target.value)}
                      value={inputVal}
                      ref={(el) => {
                        if (el) el.focus();
                      }}
                      {...addTestId(confirmModalTestIds.input)}
                    />
                  </form>
                </>
              )}
            </div>
          )}
          <div className="modal-footer">
            <Button
              className="plain"
              onClick={() => setIsOpen(false)}
              stopEventPropogation={stopButtonEventPropogation}
            >
              {no}
            </Button>
            <Button
              {...addTestId(confirmModalTestIds.yesButton)}
              disabled={!canProceed}
              stopEventPropogation={stopButtonEventPropogation}
              onClick={() => {
                callback();
                setIsOpen(false);
              }}
            >
              {yes}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
